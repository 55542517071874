import Sidebar from "components/Company/Sidebar";
import Footer from "components/Company/Footer";
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import { CSVLink } from "react-csv";
import Arrowdown from 'assets/img/down-arrow.png';
import { storeMultiUser } from "Services/action";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import loader from "assets/img/loading.gif";
import attachcolor from "assets/img/attach-black.png";
import Papa from 'papaparse';

const MultiEmplyeAdd = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const inputFile = useRef(null);
    const [loading, setLoading] = useState(false);
    const [BatchFile, setBatchFile] = useState();
    const [selectedFileName, setSelectedFileName] = useState("Upload Workers List");
    const [SelectedCompany, setSelectedCompany] = React.useState();

    const companyAdminData = useSelector((state) => state?.CompanyAdminDetail?.companyAdminDetailRec);

    const errorNotify = (error) =>
        toast.error(error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const handleChange = (e) => {
        const batchFileObj = e.target.files[0];
        if (batchFileObj) {
          setSelectedFileName(batchFileObj.name);
        } else {
          setSelectedFileName("Upload Workers List");
        }
        e.target.value = null;
        setBatchFile(batchFileObj);
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();


    function onSubmit(eData) {
      let employeeData = []; // Corrected variable name
    
      Papa.parse(BatchFile, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          results.data.forEach((employeeRec) => { // Use forEach instead of map
            if (employeeRec?.FirstName) {
              let phoneNo = employeeRec?.PhoneNo || "";
              let paddedPhoneNo = "0"; // Initialize to "0" as a string
    
              if (phoneNo) {
                phoneNo = phoneNo.replace(/[^\d]/g, "");
                if (phoneNo.startsWith("61")) {
                  phoneNo = phoneNo.slice(2);
                }
                paddedPhoneNo = phoneNo.padStart(10, '0');
              }
    
              const parts = (employeeRec?.ExpireDate || '').split('/'); // Use default value for split
              const rearrangedDate = parts.length === 3
                ? `${parts[2]}-${parts[1]}-${parts[0]}`
                : null; // Handle potential date format issues
    
              if (rearrangedDate) { // Check if rearrangedDate is valid
                const arr = {
                  name: employeeRec?.FirstName,
                  lastname: employeeRec?.LastName,
                  companies: companyAdminData?.id,
                  phone_no: paddedPhoneNo,
                  company: employeeRec?.Company,
                  role: employeeRec?.Role,
                  card_no: employeeRec?.CardNo,
                  expiry_date: rearrangedDate,
                };
                employeeData.push(arr);
              }
            }
          });
    
          dispatch(storeMultiUser(employeeData));
        },
      });
    }

    const initialStoredata = useSelector((state) => state?.EmployeStoreData);
    useEffect(() => {

        if (initialStoredata?.store_request == true) {
            setLoading(true);
        }
        if (initialStoredata?.success == true) {
            navigate('/company/employeList')
        }
        if (initialStoredata?.error) {
            setLoading(false);
            let errorData = initialStoredata?.message
            errorNotify(errorData);
        }
    }, [initialStoredata])

    const getCsvData = () => {
        const csvData = [];
        csvData.push([
            'FirstName',
            'LastName',
            'PhoneNo',
            'Company',
            'Role',
            'CardNo',
            'ExpireDate'
        ]);
        const secondRowData = [
            'John',
            'Doe',
            '1111111111',
            'E-Soft Technologies',
            'Employee',
            '123456',
            '31/12/2023'
        ];
        csvData.push(secondRowData);
        return csvData;
    };

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="md:ml-32">
                <div className="px-3 md:px-8 h-auto mt-16">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 xl:grid-cols-6">
                            <div className="xl:col-start-1 xl:col-end-7 px-4 mb-16">
                                <div class="flex flex-wrap">
                                    <div className="w-full lg:w-6/12">
                                        <h2 className="head-cust-color">Add Multi Worker</h2>
                                    </div>
                                    <div className="w-full lg:w-6/12 right-button-section">

                                        <CSVLink filename="WorkerList.csv" data={getCsvData()}>
                                            <button className="cust-button mb-3 mr-5" style={{ display: "inline-flex" }}>
                                                Download CSV Format <img src={Arrowdown} className="w-4 h-4" style={{ margin: "5px 0px 2px 5px" }} />
                                            </button>
                                        </CSVLink>
                                    </div>
                                </div>
                                <Card className="bgcustsky rounded-none">
                                    <CardBody>
                                        <form className="custom-form form-student-set" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="flex flex-wrap mt-10">
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11 add-student-part" onClick={() => inputFile.current.click()}>
                                                        <input className="addBatchInput" type="file"
                                                            accept=".csv"
                                                            onChange={handleChange}
                                                            ref={inputFile} />
                                                        {/* {loading && loading == true ? <div className="loader-image-set">
                                                            <img className="loaderImg" src={loader} />
                                                        </div> : null} */}
                                                        <input type="text" placeholder={selectedFileName} className="w-full h-full focus:outline-none" readOnly />
                                                        <img src={attachcolor} />
                                                    </div>
                                                </div>


                                            </div>
                                            <div className="flex">
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light btn-w-full">
                                                    <Button className="form-button" type="submit" disabled={loading && loading == true} >{loading && loading == true ? <img style={{ width: "25px", marginRight: "15px" }} src={loader} /> : null}
                                                        Submit</Button>
                                                </div>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
export default MultiEmplyeAdd
