import firebase from "firebase/app";
import "firebase/auth"; 

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyDIL-qbzdihG0TqupIozYqxK1ywMHHHO9c",
  authDomain: "tradie-id.firebaseapp.com",
  projectId: "tradie-id",
  storageBucket: "tradie-id.appspot.com",
  messagingSenderId: "403327439009",
  appId: "1:403327439009:web:fcd250825a526d6e30e0e0"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
