import MainStatusCard from 'components/SuperAdmin/MainStatusCard';
import Sidebar from 'components/SuperAdmin/Sidebar';
import Footer from 'components/SuperAdmin/Footer';
import { NavLink } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import { useEffect } from "react";
import { resetLoginData } from "Services/action";
import { useDispatch } from "react-redux";

const SuperAdminDashboard = () => {
    const dispatch = useDispatch();  
    useEffect(() => {
      dispatch(resetLoginData());
    },[])
    return (
        <>
            <Sidebar />
            <div className="md:ml-32">
                <div className="px-3 md:px-8 mt-16">
                    <div className="flex flex-wrap">
                        <div className="w-full lg:w-12/12 ">
                            <MainStatusCard />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
export default SuperAdminDashboard
