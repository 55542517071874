import Button from "@material-tailwind/react/Button";
import React, { useEffect, useRef, useState } from "react";
import loader from "assets/img/loading.gif";
import { resetCompanyAdminEmployeData, updateCompanyAdminEmploye } from "Services/action";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

    const EmployeEditPop = ({ employeData, onClose }) => {
        const dispatch = useDispatch();
    const location = useLocation();
    const employeDetail = employeData;
    const [loading, setLoading] = useState(false);

    const [base64Image, setBase64Image] = useState('');
    const [logoImg, setlogoImg] = useState();
    const handleImageChange = (e) => {
        setlogoImg(URL.createObjectURL(e.target.files[0]));

        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64Image(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();


    function onSubmit(employeData) {
        employeData.id = employeDetail?.id;
        employeData.company_id = employeDetail?.company_id;
        employeData.profileImage = base64Image;

        if (employeData?.phone_no.startsWith("61")) {
            employeData.phone_no = employeData.phone_no.slice(2);
            employeData.phone_no = employeData.phone_no.padStart(10, '0');
      }

        dispatch(updateCompanyAdminEmploye(employeData));
        dispatch(resetCompanyAdminEmployeData());

        onClose(employeData);

    }


    return (
        <div className="modal">
            <div className="modal-content">
                <form className="custom-form form-student-set" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-wrap mt-10">
                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                            <div class="w-full relative h-11">
                                <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="Name">
                                    Name
                                </label>
                                <input {...register("name", { required: true })}
                                    defaultValue={employeDetail?.name}
                                    placeholder="Name:" required className="w-full h-full focus:outline-none" />
                                {errors.name && <span className="error"> Name is required.</span>}
                            </div>
                        </div>

                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                            <div class="w-full relative h-11">
                                <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="lastname">
                                    Last Name
                                </label>
                                <input {...register("lastname", { required: true })} defaultValue={employeDetail?.lastname} placeholder="Last Name:" required className="w-full h-full focus:outline-none" />
                                {errors.lastname && <span className="error">Last Name is required.</span>}
                            </div>
                        </div>

                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                            <div class="w-full relative h-11">
                                <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="phone_no">
                                    Phone
                                </label>
                                <input type="number" {...register("phone_no", {
                                    required: true,
                                    minLength: 10,
                                    maxLength: 10,
                                })} defaultValue={employeDetail?.phone_no} placeholder="Phone:" className="w-full h-full remove-arrow focus:outline-none"
                                    onInput={(e) => {
                                        const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                        if (sanitizedValue.length > 10) {
                                            e.target.value = sanitizedValue.slice(0, 10);
                                        } else {
                                            e.target.value = sanitizedValue;
                                        }
                                    }}
                                />
                                {errors.phone_no && <span className="error"> Phone is required.</span>}
                            </div>
                        </div>
                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                            <div class="w-full relative h-11">
                                <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="Company">
                                    Company
                                </label>
                                <input {...register("company", { required: true })} defaultValue={employeDetail?.company && employeDetail?.company !== "undefined" ? employeDetail?.company : ""} placeholder="Company:" required className="w-full h-full focus:outline-none" />
                                {errors.company && <span className="error"> Company is required.</span>}
                            </div>
                        </div>
                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                            <div class="w-full relative h-11">
                                <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="role">
                                    Role
                                </label>
                                <input type="text" {...register("role", { required: true })} defaultValue={employeDetail?.role} placeholder="Role :" required className="w-full h-full focus:outline-none" />
                                {errors.role && <span className="error"> Role is required.</span>}
                            </div>
                        </div>

                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                            <div class="w-full relative h-11">
                                <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="card_no">
                                    Card No
                                </label>
                                <input type="number" {...register("card_no", {
                                    required: true,
                                    minLength: 4, // Minimum character length
                                    maxLength: 6,
                                })} defaultValue={employeDetail?.card_no} placeholder="Card No:" required className="w-full h-full focus:outline-none"
                                    onInput={(e) => {
                                        if (e.target.value.length > 6) {
                                            e.target.value = e.target.value.slice(0, 6); // Truncate the value to 10 characters
                                        }
                                    }}
                                />
                                {errors.card_no && <span className="error"> Card No. is required.</span>}
                            </div>
                        </div>
                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                            <div class="w-full relative h-11">
                                <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="expiry_date">
                                    Expire Date
                                </label>
                                <input type="date" {...register("expiry_date", { required: true })} defaultValue={employeDetail?.expiry_date} placeholder="Expiry Date :" required className="w-full h-full focus:outline-none" />
                                {errors.expiry_date && <span className="error"> Expiry Date is required.</span>}
                            </div>
                        </div>

                        <div className="w-full lg:w-12/12 pl-4 mb-10 font-light">
                            <div class="w-full relative">

                                {employeDetail?.profileImage ? <img src={employeDetail?.profileImage} style={{ width: "100px", height: "100px", float: "left", marginRight: "20px" }} /> : null}
                                {logoImg ? <img src={logoImg} style={{ width: "100px", height: "100px", float: "left", marginRight: "20px" }} /> : null}

                            </div>

                        </div>
                        <div className="w-full lg:w-12/12 pr-4 mb-10 font-light">
                            <div class="w-full relative">
                                <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="profileImage">
                                    Profile Image
                                </label>
                                <input type="file" className="mt-2" accept="image/*" onChange={handleImageChange} />
                            </div>

                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-full lg:w-6/12 pr-2 mb-10 font-light">
                            <Button className="form-button" type="submit" disabled={loading && loading == true}>
                                {loading && loading == true ? <img style={{ width: "25px", marginRight: "15px" }} src={loader} /> : null}
                                Submit
                            </Button>
                        </div>
                        <div className="w-full lg:w-6/12 pr-2 mb-10 font-light">
                            <Button className="form-button" type="button" onClick={() => onClose(null)}>Cancel</Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EmployeEditPop;
