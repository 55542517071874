import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import SuperAdminNavbar from "./SuperAdminNavbar";
import {getStatistics, superAdminLogout } from "../../Services/action";
import { useDispatch, useSelector } from "react-redux";
import WebLogo from 'assets/img/logo.png';
import CompanyIcon from 'assets/img/Company.png';
import StudentIcon from 'assets/img/student.png';
import AppliedStudentsIcon from 'assets/img/AppliedStudents.png';
import LogoutImg from 'assets/img/new-setting.png';

export default function SuperAdminSidebar() {
  const [showSidebar, setShowSidebar] = useState("-left-64");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialdata = useSelector((state) => state.SuperAdminLoginData);
  let superAdmintokens = initialdata.superAdmintoken;

  const superAdminDetail = useSelector((state) => state?.SuperAdminDetail?.superAdminDetailRec);

  useEffect(() => {
    if (superAdmintokens == "" || superAdmintokens == null) {
      navigate("/superAdmin");
    }
  });
  const logout = () => {
    dispatch(superAdminLogout());
    navigate("/superAdmin");
  };

  useEffect(() => {
    const datas = {
      id: superAdminDetail?.id
  }
  dispatch(getStatistics(datas))
  },[superAdminDetail]);

  return (
    <>
      <SuperAdminNavbar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className={`h-screen custom-sidebar custom-sidebar fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-32 z-10 py-4 transition-all duration-300`}>
        <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
          <NavLink to="/superAdmin/dashboard" className="mt-2 text-center w-full inline-block">
            <img className="w-16 h-16" src={WebLogo} style={{ margin: "auto" }} />
          </NavLink>
          <div className="flex flex-col">
            <hr className="my-2 min-w-full" />
            <ul className="flex-col min-w-full flex list-none">
              <li className="rounded-lg mb-2">
                <NavLink
                  to="/superAdmin/companies"
                  className="flex flex-col items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                  activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md">
                  <img src={CompanyIcon} className="w-16 h-16" style={{ margin: "auto" }} />
                  <span className="text-sm text-white font-bold">Companies</span>
                </NavLink>
                <hr className="my-2 min-w-full" />
              </li>
              <li className="rounded-lg mb-2">
                <NavLink
                  to="/superAdmin/employeList"
                  className="flex flex-col items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                  activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md">
                  <img src={StudentIcon} className="w-16 h-16" style={{ margin: "auto" }} />
                  <span className="text-sm text-white font-bold">Workers</span>
                </NavLink>
                <hr className="my-2 min-w-full" />
              </li>
           
              <li className="rounded-lg mb-2">
                <NavLink
                  to="/superAdmin/selfRegisterdEmployeList"
                  className="flex flex-col items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                  activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md">
                  <img src={AppliedStudentsIcon} className="w-16 h-16" style={{ margin: "auto" }} />
                  <span className="text-sm text-white font-bold">Self Employes</span>
                </NavLink>
                <hr className="my-2 min-w-full" />
              </li>
              <li className="rounded-lg mb-2">
              <NavLink
                  to="/superAdmin/profile"
                  className="flex flex-col items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                  activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md">
                  <img src={LogoutImg} className="w-16 h-16" style={{ margin: "auto" }} />
                  <span className="text-sm text-white font-bold">Profile</span>
                </NavLink>
                <hr className="my-2 min-w-full" />
                </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
