import Sidebar from "components/Company/Sidebar";
import Footer from "components/Company/Footer";
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import { storeCompanyAdminEmploye, resetCompanyAdminEmployeData } from "Services/action";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import loader from "assets/img/loading.gif";

const EmployeAdd = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const inputFile = useRef(null);
    const [loading, setLoading] = useState(false);
    const [base64Image, setBase64Image] = useState('');

    const companyAdminData = useSelector((state) => state?.CompanyAdminDetail?.companyAdminDetailRec);

    const errorNotify = (error) =>
        toast.error(error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();


    function onSubmit(employeData) {
        //const companyData = companyAdminData?.id;
        // const companyIds = companyData?.toString().split(",");
         employeData.companies = companyAdminData?.id;
        employeData.profileImage = base64Image;
        if (employeData?.phone_no.startsWith("61")) {
              employeData.phone_no = employeData.phone_no.slice(2);
              employeData.phone_no = employeData.phone_no.padStart(10, '0');
        }
        console.log(employeData);
        dispatch(storeCompanyAdminEmploye(employeData));
        dispatch(resetCompanyAdminEmployeData());

    }

    const initialStoredata = useSelector((state) => state?.CompanyEmployeStoreData);

    useEffect(() => {

        if (initialStoredata?.store_request == true) {
            setLoading(true);
        }
        if (initialStoredata?.success == true) {
            navigate('/company/employeList')
        }
        if (initialStoredata?.error) {
            setLoading(false);
            let errorData = initialStoredata?.message
            errorNotify(errorData);
        }
    }, [initialStoredata])
    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64Image(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="md:ml-32">
                <div className="px-3 md:px-8 h-auto mt-16">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 xl:grid-cols-6">
                            <div className="xl:col-start-1 xl:col-end-7 px-4 mb-16">
                                <div>
                                    <h2 className="head-cust-color">Add Worker</h2>
                                </div>
                                <Card className="bgcustsky rounded-none">
                                    <CardBody>
                                        <form className="custom-form form-student-set" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="flex flex-wrap mt-10">
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="Name">
                                                            First Name
                                                        </label>
                                                        <input {...register("name", { required: true })} placeholder="First Name:" required className="w-full h-full focus:outline-none" />
                                                        {errors.name && <span className="error">First Name is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="lastname">
                                                            Last Name
                                                        </label>
                                                        <input {...register("lastname", { required: true })} placeholder="Last Name:" required className="w-full h-full focus:outline-none" />
                                                        {errors.lastname && <span className="error">Last Name is required.</span>}
                                                    </div>
                                                </div>
                                                {/* <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="email">
                                                            Email
                                                        </label>
                                                        <input type="email" {...register("email", { required: true })} placeholder="Email:" required className="w-full h-full focus:outline-none" />
                                                        {errors.email && <span className="error"> Email is required.</span>}
                                                    </div>
                                                </div> */}
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="phone_no">
                                                            Phone (<a href="javascript:void(0)" title="Special character and spaces not allowed.">?</a>)
                                                        </label>
                                                        <input type="number" {...register("phone_no", {
                                                            required: true,
                                                            minLength: 10,
                                                            maxLength: 10,
                                                        })} placeholder="Phone:" required className="w-full h-full remove-arrow focus:outline-none"
                                                            onInput={(e) => {
                                                                const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                                if (sanitizedValue.length > 10) {
                                                                    e.target.value = sanitizedValue.slice(0, 10);
                                                                } else {
                                                                    e.target.value = sanitizedValue;
                                                                }
                                                            }} />
                                                        {errors.phone_no && <span className="error"> Phone is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="Company">
                                                            Company
                                                        </label>
                                                        <input {...register("company", { required: true })} placeholder="Company:" required className="w-full h-full focus:outline-none" />
                                                        {errors.company && <span className="error"> Company is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="role">
                                                            Role
                                                        </label>
                                                        <input type="text" {...register("role", { required: true })} placeholder="Role :" required className="w-full h-full focus:outline-none" />
                                                        {errors.role && <span className="error"> Role is required.</span>}
                                                    </div>
                                                </div>

                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="card_no">
                                                            Card No
                                                        </label>
                                                        <input type="number" {...register("card_no",
                                                        {
                                                            required: true,
                                                            minLength: 4, // Minimum character length
                                                            maxLength: 6,
                                                        })} 
                                                        placeholder="Card No:" required className="w-full h-full focus:outline-none"
                                                        onInput={(e) => {
                                                            if (e.target.value.length > 6) {
                                                                e.target.value = e.target.value.slice(0, 6); // Truncate the value to 10 characters
                                                            }
                                                        }}
                                                        />
                                                        {errors.card_no && <span className="error"> Card No. is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="expiry_date">
                                                            Expire Date
                                                        </label>
                                                        <input type="date" {...register("expiry_date", { required: true })} placeholder="Expiry Date :" required className="w-full h-full focus:outline-none" />
                                                        {errors.expiry_date && <span className="error"> Expiry Date is required.</span>}
                                                    </div>
                                                </div>

                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="profileImage">
                                                            Profile Image.
                                                        </label>
                                                        <input type="file" accept="image/*" onChange={handleImageChange} required className="w-full h-full focus:outline-none" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="w-full lg:w-6/12 pl-4 mt-5 mb-10 font-light btn-w-full">
                                                    <Button className="form-button" type="submit" disabled={loading && loading == true} >{loading && loading == true ? <img style={{ width: "25px", marginRight: "15px" }} src={loader} /> : null}
                                                        Submit</Button>
                                                </div>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
export default EmployeAdd
