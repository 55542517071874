import { combineReducers } from "redux";
import { GETDETAILS, Set_User_Detail, SuperAdminUserLogin, Set_Reset_SuperAdmin_Login_Data, Set_SuperAdmin_Login, SuperAdmin_Login_Fail, set_SuperAdmin_Local_Store_Data, Set_Super_Admin_Details, Set_Handle_User_Status_Data,  Set_Handle_User_Status_Data_Fail,Set_Reset_User_Status_Data, Set_Handle_Company_Status_Data,  Set_Handle_Company_Status_Data_Fail,Set_Reset_Company_Status_Data, Set_Reset_Company_Data, Store_Company, Store_Company_Request, Set_Store_Company_Data, Set_Store_Company_Data_Fail, Get_Company, Set_Company_List, Set_SuperAdmin_Logout, AdminUserLogin, Set_Admin_Login, Set_Company_Admin_Details, Admin_Login_Fail, set_Local_Store_Data, Set_Reset_Update_Profile_Data, Set_Update_Profile_Request, Set_Update_Profile_Data, Set_Update_Profile_Fail, Set_Admin_Logout, Store_Multi_User, Admin_Login_Invalid, Set_Reset_Login_Data, Get_Statistics, Set_Statistics, Set_All_Report_For_Ceo } from "./constant";

import { Update_Company_Request, Set_Update_Company_Data, Set_Update_Company_Data_Fail } from "./constant"

import { Mail_Forward_Request, Set_Mail_Forward_Data, Set_Mail_Forward_Data_Fail, Set_Reset_Mail_Forward_Data } from "./constant"


import { Get_All_Employes, Set_All_Employes, Store_SuperAdmin_Employe, Store_SuperAdmin_Employe_Request, Set_Store_SuperAdmin_Employe_Data, Set_Store_SuperAdmin_Employe_Data_Fail, Set_Reset_SuperAdmin_Employe_Data, Update_SuperAdmin_Employe_Request, Set_Update_SuperAdmin_Employe_Data_Fail, Set_Update_SuperAdmin_Employe_Data, Store_Multi_Employe_Request, Set_Store_Multi_Employe_Data, Set_Store_Multi_Employe_Data_Fail } from "./constant";

import { Get_Employe_By_Company, Set_Employe_By_Company, Store_CompanyAdmin_Employe, Store_CompanyAdmin_Employe_Request, Set_Store_CompanyAdmin_Employe_Data, Set_Store_CompanyAdmin_Employe_Data_Fail, Set_Reset_CompanyAdmin_Employe_Data, Update_CompanyAdmin_Employe_Request, Set_Update_CompanyAdmin_Employe_Data, Set_Update_CompanyAdmin_Employe_Data_Fail } from "./constant";

import { Get_Expired_Emp_By_Company, Set_Expired_Emp_By_Company, Set_Reset_Company_Expired_Emp_Data } from "./constant";



import { Get_Assistant_By_Company, Set_Assistant_By_Company,Store_Company_Assistant, Store_Company_Assistant_Request, Set_Store_Company_Assistant_Data, Set_Store_Company_Assistant_Data_Fail, Set_Reset_Company_Assistant_Data, Update_Company_Assistant_Request,Set_Update_Company_Assistant_Data,Set_Update_Company_Assistant_Data_Fail } from "./constant";

import { Company_Forgot_Pass_Request, Update_Password_Request,Set_Reset_Update_Password_Data, Set_Reset_Company_Forgot_Pass_Data,Set_Company_Forgot_Pass_Data,Set_Update_Password_Data, Set_Company_Forgot_Pass_Data_Fail,Set_Update_Password_Data_Fail } from "./constant";



const data = {
    error: ""
}
const superAdminData = {
    error: "",
    superAdminUserId: "",
    superAdminUserName: "",
    superAdminUserEmail: "",
    superAdminUserPhone: "",
    superAdmintoken: "",
    superAdminUserRole: "",
    superAdminUserAddress: "",
    superAdminUserCity: "",
    superAdminUserCountry: ""
}

const superAdminDetailData = {
    error: "",
    superAdminDetailRec: [],
}
const companyAdminDetailData = {
    error: "",
    companyAdminDetailRec: [],
}
const mailForwardData = {
    store_request: false,
    success: false,
    error: false,
}
const companyStoreData = {
    store_request: false,
    success: false,
    error: false,
}
const employeStoreData = {
    store_request: false,
    success: false,
    error: false,
}

const userStatusData = {
    store_request: false,
    success: false,
    error: false,
    successMessage: false,
}
const companyStatusData = {
    store_request: false,
    success: false,
    error: false,
    successMessage: false,
}
const companyData = {
    error: "",
    companyRec: [],
}
const employeData = {
    error: "",
    employeRec: [],
}


const adminData = {
    error: "",
    adminUserId: "",
    superAdminId: "",
    adminName: "",
    adminUsername: "",
    adminUserEmail: "",
    adminUserPhone: "",
    admintoken: "",
    adminUserRole: "",
    adminUserAddress: "",
    adminUserCity: "",
    adminUserCountry: "",
    store_request: false,
    success: false,
    error: false,
}
const CompanyForgotPassData = {
    store_request: false,
    success: false,
    error: false,
}
const UpdatePasswordData = {
    store_request: false,
    success: false,
    error: false,
}

const companyEmployeStoreData = {
    store_request: false,
    success: false,
    error: false,
}
const companyAssistantStoreData = {
    store_request: false,
    success: false,
    error: false,
}

const companyEmployeData = {
    error: "",
    companyEmployeRec: [],
}
const companyExpiredEmpData = {
    error: "",
    companyExpiredEmpRec: [],
}
const companyAssistantData = {
    error: "",
    companyAssistantRec: [],
}
const updateProfileData = {
    store_request: false,
    success: false,
    error: false,
    successMessage: false,
}

const userDetailData = {
    error: "",
    userDetailRec: [],
}
const staticsData = {
    error: "",
    staticsRec: [],
}
const allReportCeoData = {
    error: "",
    reportScansRec: [],
}

const multiUserStoreData = {
    store_request: false,
    success: false,
    error: false,
}
export const Details = (initialdata = data, action) => {
    switch (action.type) {
        case GETDETAILS:
            return initialdata;
            break;
        default:
            return initialdata;
            break;
    }
}

export const UserDetailRecord = (initialdata = userDetailData, action) => {
    switch (action.type) {
        case Set_User_Detail:
            initialdata = { ...initialdata, userDetailRec: action.result }
            return initialdata
            break;
        default:
            return initialdata
            break;
    }
}


export const SuperAdminLoginData = (initialdata = superAdminData, action) => {
    switch (action.type) {
        case SuperAdminUserLogin:
            return initialdata;
            break;
        case Set_Reset_SuperAdmin_Login_Data:
            initialdata = { ...initialdata, success: false, store_request: false, error: false }
            return initialdata;
            break;
        case Set_SuperAdmin_Login:
            let userId = localStorage.getItem('superAdminUserId');
            initialdata = { ...initialdata, superAdminUserId: userId }
            let username = localStorage.getItem('superAdminUserName');
            initialdata = { ...initialdata, superAdminUserName: username }
            let userEmail = localStorage.getItem('superAdminUserEmail');
            initialdata = { ...initialdata, superAdminUserEmail: userEmail }
            let userPhone = localStorage.getItem('superAdminUserPhone');
            initialdata = { ...initialdata, superAdminUserPhone: userPhone }
            let token = localStorage.getItem('superAdmintoken');
            initialdata = { ...initialdata, superAdmintoken: token }
            let userRole = localStorage.getItem('superAdminUserRole');
            initialdata = { ...initialdata, superAdminUserRole: userRole }
            let userAddress = localStorage.getItem('superAdminUserAddress');
            initialdata = { ...initialdata, superAdminUserAddress: userAddress }
            let userCity = localStorage.getItem('superAdminUserCity');
            initialdata = { ...initialdata, superAdminUserCity: userCity }
            let userCountry = localStorage.getItem('superAdminUserCountry');
            initialdata = { ...initialdata, superAdminUserCountry: userCountry }
            return initialdata;
            break;
        case SuperAdmin_Login_Fail:
            initialdata = { ...initialdata, error: action.errMessage }
            return initialdata;
            break;
        case set_SuperAdmin_Local_Store_Data:
            let userId1 = localStorage.getItem('superAdminUserId');
            initialdata = { ...initialdata, superAdminUserId: userId1 }
            let username1 = localStorage.getItem('superAdminUserName');
            initialdata = { ...initialdata, superAdminUserName: username1 }
            let userEmail1 = localStorage.getItem('superAdminUserEmail');
            initialdata = { ...initialdata, superAdminUserEmail: userEmail1 }
            let userPhone1 = localStorage.getItem('superAdminUserPhone');
            initialdata = { ...initialdata, superAdminUserPhone: userPhone1 }
            let token1 = localStorage.getItem('superAdmintoken');
            initialdata = { ...initialdata, superAdmintoken: token1 }
            let userRole1 = localStorage.getItem('superAdminUserRole');
            initialdata = { ...initialdata, superAdminUserRole: userRole1 }
            let userAddress1 = localStorage.getItem('superAdminUserAddress');
            initialdata = { ...initialdata, superAdminUserAddress: userAddress1 }
            let userCity1 = localStorage.getItem('superAdminUserCity');
            initialdata = { ...initialdata, superAdminUserCity: userCity1 }
            let userCountry1 = localStorage.getItem('superAdminUserCountry');
            initialdata = { ...initialdata, superAdminUserCountry: userCountry1 }
            return initialdata;
            break;
        case Set_SuperAdmin_Logout:
            localStorage.removeItem('superAdminUserId');
            localStorage.removeItem('superAdminUserName');
            localStorage.removeItem('superAdminUserEmail');
            localStorage.removeItem('superAdminUserPhone');
            localStorage.removeItem('superAdmintoken');
            localStorage.removeItem('superAdminUserRole');
            localStorage.removeItem('superAdminUserAddress');
            localStorage.removeItem('superAdminUserCity');
            localStorage.removeItem('superAdminUserCountry');
            initialdata = { ...initialdata, superAdminUserId: "" }
            initialdata = { ...initialdata, superAdminUserName: "" }
            initialdata = { ...initialdata, superAdminUserEmail: "" }
            initialdata = { ...initialdata, superAdminUserPhone: "" }
            initialdata = { ...initialdata, superAdmintoken: "" }
            initialdata = { ...initialdata, superAdminUserRole: "" }
            initialdata = { ...initialdata, superAdminUserAddress: "" }
            initialdata = { ...initialdata, superAdminUserCity: "" }
            initialdata = { ...initialdata, superAdminUserCountry: "" }

            return initialdata;
            break;
        default:
            return initialdata;
            break;
    }
}
export const handleUserStatusData = (initialdata = userStatusData, action) => {
    switch (action.type) {

        case Set_Handle_User_Status_Data:
            initialdata = { ...initialdata, success: true, store_request: false, error: false, message: action?.result?.message }
            return initialdata;
            break;
        case Set_Handle_User_Status_Data_Fail:
            initialdata = { ...initialdata, error: true, store_request: false, success: false }
            return initialdata;
            break;
        case Set_Reset_User_Status_Data:
            initialdata = { ...initialdata, success: false, store_request: false, error: false }
            return initialdata;
            break;
        default:
            return initialdata;
            break;
    }
}
export const handleCompanyStatusData = (initialdata = companyStatusData, action) => {
    switch (action.type) {
        case Set_Handle_Company_Status_Data:
            initialdata = { ...initialdata, success: true, store_request: false, error: false, message: action?.result?.message }
            return initialdata;
            break;
        case Set_Handle_Company_Status_Data_Fail:
            initialdata = { ...initialdata, error: true, store_request: false, success: false, message: action?.errMessage }
            return initialdata;
            break;
        case Set_Reset_Company_Status_Data:
            initialdata = { ...initialdata, success: false, store_request: false, error: false }
            return initialdata;
            break;
        default:
            return initialdata;
            break;
    }
}


export const SuperAdminDetail = (initialdata = superAdminDetailData, action) => {
    switch (action.type) {
        case Set_Super_Admin_Details:
            initialdata = { ...initialdata, superAdminDetailRec: action?.result }
            return initialdata
            break;
        default:
            return initialdata
            break;
    }
}


export const MailForwardData = (initialdata = mailForwardData, action) => {
    switch (action.type) {
        case Mail_Forward_Request:
            initialdata = { ...initialdata, success: false, store_request: true, error: false }
            return initialdata;
            break;
        case Set_Mail_Forward_Data:
            initialdata = {
                ...initialdata, success: true, store_request: false, error: false, message: action?.result?.message
            }
            return initialdata;
            break;
        case Set_Mail_Forward_Data_Fail:
            initialdata = { ...initialdata, error: true, store_request: false, success: false, message: action?.errMessage }
            return initialdata;
            break;
        case Set_Reset_Mail_Forward_Data:
            initialdata = { ...initialdata, success: false, store_request: false, error: false }
            return initialdata;
            break;

        default:
            return initialdata;
            break;
    }
}


export const CompanyStoreData = (initialdata = companyStoreData, action) => {
    switch (action.type) {
        case Store_Company:
            return initialdata;
            break;
        case Store_Company_Request:
            initialdata = { ...initialdata, success: false, store_request: true, error: false }
            return initialdata;
            break;
        case Set_Store_Company_Data:
            initialdata = {
                ...initialdata, success: true, store_request: false, error: false, message: action?.result?.message
            }
            return initialdata;
            break;
        case Set_Store_Company_Data_Fail:
            initialdata = { ...initialdata, error: true, store_request: false, success: false, message: action?.errMessage }
            return initialdata;
            break;
        case Update_Company_Request:
            initialdata = { ...initialdata, success: false, store_request: true, error: false }
            return initialdata;
            break;
        case Set_Update_Company_Data:
            initialdata = {
                ...initialdata, success: true, store_request: false, error: false, message: action?.result?.message
            }
            return initialdata;
            break;
        case Set_Update_Company_Data_Fail:
            initialdata = { ...initialdata, error: true, store_request: false, success: false, message: action?.errMessage }
            return initialdata;
            break;

        case Set_Reset_Company_Data:
            initialdata = { ...initialdata, success: false, store_request: false, error: false }
            return initialdata;
            break;
        default:
            return initialdata;
            break;
    }
}


export const CompanyRecord = (initialdata = companyData, action) => {
    switch (action.type) {
        case Get_Company:
            return initialdata
            break;
        case Set_Company_List:
            initialdata = { ...initialdata, companyRec: action.result }
            return initialdata
            break;
        default:
            return initialdata
            break;
    }
}

export const EmployeStoreData = (initialdata = employeStoreData, action) => {
    switch (action.type) {
        case Store_SuperAdmin_Employe:
            return initialdata;
            break;
        case Store_SuperAdmin_Employe_Request:
            initialdata = { ...initialdata, success: false, store_request: true, error: false }
            return initialdata;
            break;
        case Set_Store_SuperAdmin_Employe_Data:
            initialdata = {
                ...initialdata, success: true, store_request: false, error: false, message: action?.result?.message
            }
            return initialdata;
            break;

        case Set_Store_SuperAdmin_Employe_Data_Fail:
            initialdata = { ...initialdata, error: true, store_request: false, success: false, message: action?.errMessage }
            return initialdata;
            break;

        case Update_SuperAdmin_Employe_Request:
            initialdata = { ...initialdata, success: false, store_request: true, error: false }
            return initialdata;
            break;
        case Set_Update_SuperAdmin_Employe_Data:
            initialdata = {
                ...initialdata, success: true, store_request: false, error: false, message: action?.result?.message
            }
            return initialdata;
            break;

        case Set_Update_SuperAdmin_Employe_Data_Fail:
            initialdata = { ...initialdata, error: true, store_request: false, success: false, message: action?.errMessage }
            return initialdata;
            break;

        case Store_Multi_Employe_Request:
            initialdata = { ...initialdata, success: false, store_request: true, error: false }
            return initialdata;
            break;
        case Set_Store_Multi_Employe_Data:
            initialdata = {
                ...initialdata, success: true, store_request: false, error: false, message: action?.result?.message
            }
            return initialdata;
            break;

        case Set_Store_Multi_Employe_Data_Fail:
            initialdata = { ...initialdata, error: true, store_request: false, success: false, message: action?.errMessage }
            return initialdata;
            break;

        case Set_Reset_SuperAdmin_Employe_Data:
            initialdata = { ...initialdata, success: false, store_request: false, error: false }
            return initialdata;
            break;
        default:
            return initialdata;
            break;
    }
}
export const EmployeRecord = (initialdata = employeData, action) => {
    switch (action.type) {
        case Get_All_Employes:
            return initialdata
            break;
        case Set_All_Employes:
            initialdata = { ...initialdata, employeRec: action.result }
            return initialdata
            break;
        default:
            return initialdata
            break;
    }
}


export const AdminLoginData = (initialdata = adminData, action) => {
    switch (action.type) {
        case AdminUserLogin:
            return initialdata;
            break;
        case Set_Reset_Login_Data:
            initialdata = { ...initialdata, success: false, store_request: false, error: false }
            return initialdata;
            break;
        case Admin_Login_Invalid:
            initialdata = { ...initialdata, error: action.result.error }
            return initialdata;
            break;
        case Set_Admin_Login:
            let userId = localStorage.getItem('adminUserId');
            initialdata = { ...initialdata, adminUserId: userId }
            let superAdminId = localStorage.getItem('superAdminId');
            initialdata = { ...initialdata, superAdminId: superAdminId }
            let adminname = localStorage.getItem('adminName');
            initialdata = { ...initialdata, adminName: adminname }
            let username = localStorage.getItem('adminUserName');
            initialdata = { ...initialdata, adminUserName: username }
            let userEmail = localStorage.getItem('adminUserEmail');
            initialdata = { ...initialdata, adminUserEmail: userEmail }
            let userPhone = localStorage.getItem('adminUserPhone');
            initialdata = { ...initialdata, adminUserPhone: userPhone }
            let token = localStorage.getItem('admintoken');
            initialdata = { ...initialdata, admintoken: token }
            let userRole = localStorage.getItem('adminUserRole');
            initialdata = { ...initialdata, adminUserRole: userRole }
            let userAddress = localStorage.getItem('adminUserAddress');
            initialdata = { ...initialdata, adminUserAddress: userAddress }
            let userCity = localStorage.getItem('adminUserCity');
            initialdata = { ...initialdata, adminUserCity: userCity }
            let userCountry = localStorage.getItem('adminUserCountry');
            initialdata = { ...initialdata, adminUserCountry: userCountry }
            return initialdata;
            break;
        case Admin_Login_Fail:
            initialdata = { ...initialdata, error: action.errMessage }
            return initialdata;
            break;
        case set_Local_Store_Data:
            let userId1 = localStorage.getItem('adminUserId');
            initialdata = { ...initialdata, adminUserId: userId1 }
            let superAdminId1 = localStorage.getItem('superAdminId');
            initialdata = { ...initialdata, superAdminId: superAdminId1 }
            let adminname1 = localStorage.getItem('adminName');
            initialdata = { ...initialdata, adminName: adminname1 }
            let username1 = localStorage.getItem('adminUserName');
            initialdata = { ...initialdata, adminUserName: username1 }
            let userEmail1 = localStorage.getItem('adminUserEmail');
            initialdata = { ...initialdata, adminUserEmail: userEmail1 }
            let userPhone1 = localStorage.getItem('adminUserPhone');
            initialdata = { ...initialdata, adminUserPhone: userPhone1 }
            let token1 = localStorage.getItem('admintoken');
            initialdata = { ...initialdata, admintoken: token1 }
            let userRole1 = localStorage.getItem('adminUserRole');
            initialdata = { ...initialdata, adminUserRole: userRole1 }
            let userAddress1 = localStorage.getItem('adminUserAddress');
            initialdata = { ...initialdata, adminUserAddress: userAddress1 }
            let userCity1 = localStorage.getItem('adminUserCity');
            initialdata = { ...initialdata, adminUserCity: userCity1 }
            let userCountry1 = localStorage.getItem('adminUserCountry');
            initialdata = { ...initialdata, adminUserCountry: userCountry1 }
            return initialdata;
            break;
        case Set_Admin_Logout:
            localStorage.removeItem('adminUserId');
            localStorage.removeItem('superAdminId');
            localStorage.removeItem('adminName');
            localStorage.removeItem('adminUserName');
            localStorage.removeItem('adminUserEmail');
            localStorage.removeItem('adminUserPhone');
            localStorage.removeItem('admintoken');
            localStorage.removeItem('adminUserRole');
            localStorage.removeItem('adminUserAddress');
            localStorage.removeItem('adminUserCity');
            localStorage.removeItem('adminUserCountry');
            initialdata = { ...initialdata, adminUserId: "" }
            initialdata = { ...initialdata, superAdminId: "" }
            initialdata = { ...initialdata, adminName: "" }
            initialdata = { ...initialdata, adminUserName: "" }
            initialdata = { ...initialdata, adminUserEmail: "" }
            initialdata = { ...initialdata, adminUserPhone: "" }
            initialdata = { ...initialdata, admintoken: "" }
            initialdata = { ...initialdata, adminUserRole: "" }
            initialdata = { ...initialdata, adminUserAddress: "" }
            initialdata = { ...initialdata, adminUserCity: "" }
            initialdata = { ...initialdata, adminUserCountry: "" }
            return initialdata;
            break;
        default:
            return initialdata;
            break;
    }
}


export const CompanyForgotPass = (initialdata = CompanyForgotPassData, action) => {
    switch (action.type) {
        case Company_Forgot_Pass_Request:
            initialdata = { ...initialdata, success: false, store_request: true, error: false, message: false }
            return initialdata;
            break;
      
        case Set_Reset_Company_Forgot_Pass_Data:
            initialdata = { ...initialdata, success: false, store_request: false, error: false, message: false }
            return initialdata;
            break;
        
        case Set_Company_Forgot_Pass_Data:
            initialdata = { ...initialdata, success: true, store_request: false, error: false, message: action?.result?.message }
            return initialdata;
            break;
        case Set_Company_Forgot_Pass_Data_Fail:
            initialdata = { ...initialdata, error: true, store_request: false, success: false, message: action?.errMessage }
            return initialdata;
            break;
        default:
            return initialdata;
            break;
    }

}


export const UpdatePassword = (initialdata = UpdatePasswordData, action) => {
    switch (action.type) {
        
        case Update_Password_Request:
            initialdata = { ...initialdata, success: false, store_request: true, error: false, message: false }
            return initialdata;
            break;
        case Set_Reset_Update_Password_Data:
            initialdata = { ...initialdata, success: false, store_request: false, error: false, message: false }
            return initialdata;
            break;
        case Set_Update_Password_Data:
            initialdata = { ...initialdata, success: true, store_request: false, error: false, message: action?.result?.message }
            return initialdata;
            break;
        case Set_Update_Password_Data_Fail:
            initialdata = { ...initialdata, error: true, store_request: false, success: false, message: action?.errMessage }
            return initialdata;
            break;
        default:
            return initialdata;
            break;
    }

}

export const UpdateProfile = (initialdata = updateProfileData, action) => {
    switch (action.type) {
        case Set_Reset_Update_Profile_Data:
            initialdata = { ...initialdata, success: false, store_request: false, error: false, message: false }
            return initialdata;
            break;
        case Set_Update_Profile_Request:
            initialdata = { ...initialdata, success: false, store_request: true, error: false, message: false }
            return initialdata;
            break;
        case Set_Update_Profile_Data:
            initialdata = { ...initialdata, success: true, store_request: false, error: false, message: action?.result?.message }
            return initialdata;
            break;
        case Set_Update_Profile_Fail:
            initialdata = { ...initialdata, error: true, store_request: false, success: false, message: action.result.message }
            return initialdata;
            break;
        default:
            return initialdata;
            break;
    }
}


export const CompanyAdminDetail = (initialdata = companyAdminDetailData, action) => {
    switch (action.type) {
        case Set_Company_Admin_Details:
            initialdata = { ...initialdata, companyAdminDetailRec: action?.result }
            return initialdata
            break;
        default:
            return initialdata
            break;
    }
}


export const CompanyEmployeStoreData = (initialdata = companyEmployeStoreData, action) => {
    switch (action.type) {
        case Store_CompanyAdmin_Employe:
            return initialdata;
            break;
        case Store_CompanyAdmin_Employe_Request:
            initialdata = { ...initialdata, success: false, store_request: true, error: false }
            return initialdata;
            break;
        case Set_Store_CompanyAdmin_Employe_Data:
            initialdata = {
                ...initialdata, success: true, store_request: false, error: false, message: action?.result?.message
            }
            return initialdata;
            break;

        case Set_Store_CompanyAdmin_Employe_Data_Fail:
            initialdata = { ...initialdata, error: true, store_request: false, success: false, message: action?.errMessage }
            return initialdata;
            break;

        case Update_CompanyAdmin_Employe_Request:
            initialdata = { ...initialdata, success: false, store_request: true, error: false }
            return initialdata;
            break;
        case Set_Update_CompanyAdmin_Employe_Data:
            initialdata = {
                ...initialdata, success: true, store_request: false, error: false, message: action?.result?.message
            }
            return initialdata;
            break;

        case Set_Update_CompanyAdmin_Employe_Data_Fail:
            initialdata = { ...initialdata, error: true, store_request: false, success: false, message: action?.errMessage }
            return initialdata;
            break;

        case Set_Reset_CompanyAdmin_Employe_Data:
            initialdata = { ...initialdata, success: false, store_request: false, error: false }
            return initialdata;
            break;
        case Set_Reset_Company_Expired_Emp_Data:
            initialdata = { ...initialdata, success: false, store_request: false, error: false }
            return initialdata;
            break;
        default:
            return initialdata;
            break;
    }
}

export const CompanyEmployeRecord = (initialdata = companyEmployeData, action) => {
    switch (action.type) {
        case Get_Employe_By_Company:
            return initialdata
            break;
        case Set_Employe_By_Company:
            initialdata = { ...initialdata, companyEmployeRec: action.result }
            return initialdata
            break;
        default:
            return initialdata
            break;
    }
}

export const CompanyExpiredEmpRecord = (initialdata = companyExpiredEmpData, action) => {
    switch (action.type) {
        case Get_Expired_Emp_By_Company:
            return initialdata
            break;
        case Set_Expired_Emp_By_Company:
            initialdata = { ...initialdata, companyExpiredEmpRec: action.result }
            return initialdata
            break;
        default:
            return initialdata
            break;
    }
}

export const CompanyAssistantStoreData = (initialdata = companyAssistantStoreData, action) => {
    switch (action.type) {
        case Store_Company_Assistant:
            return initialdata;
            break;
        case Store_Company_Assistant_Request:
            initialdata = { ...initialdata, success: false, store_request: true, error: false }
            return initialdata;
            break;
        case Set_Store_Company_Assistant_Data:
            initialdata = {
                ...initialdata, success: true, store_request: false, error: false, message: action?.result?.message
            }
            return initialdata;
            break;

        case Set_Store_Company_Assistant_Data_Fail:
            initialdata = { ...initialdata, error: true, store_request: false, success: false, message: action?.errMessage }
            return initialdata;
            break;

        case Update_Company_Assistant_Request:
            initialdata = { ...initialdata, success: false, store_request: true, error: false }
            return initialdata;
            break;
        case Set_Update_Company_Assistant_Data:
            initialdata = {
                ...initialdata, success: true, store_request: false, error: false, message: action?.result?.message
            }
            return initialdata;
            break;

        case Set_Update_Company_Assistant_Data_Fail:
            initialdata = { ...initialdata, error: true, store_request: false, success: false, message: action?.errMessage }
            return initialdata;
            break;

        case Set_Reset_Company_Assistant_Data:
            initialdata = { ...initialdata, success: false, store_request: false, error: false }
            return initialdata;
            break;
        default:
            return initialdata;
            break;
    }
}

export const CompanyAssistantRecord = (initialdata = companyAssistantData, action) => {
    switch (action.type) {
        case Get_Assistant_By_Company:
            return initialdata
            break;
        case Set_Assistant_By_Company:
            initialdata = { ...initialdata, companyAssistantRec: action.result }
            return initialdata
            break;
        default:
            return initialdata
            break;
    }
}

export const MultiUserStoreData = (initialdata = multiUserStoreData, action) => {
    switch (action.type) {
        case Store_Multi_User:
            return initialdata;
            break;
        default:
            return initialdata;
            break;
    }
}

export const StaticsRecord = (initialdata = staticsData, action) => {
    switch (action.type) {
        case Get_Statistics:
            return initialdata
            break;
        case Set_Statistics:
            initialdata = { ...initialdata, staticsRec: action?.result }
            return initialdata
            break;
        default:
            return initialdata
            break;
    }
}

export const AllReportForCeo = (initialdata = allReportCeoData, action) => {
    switch (action.type) {
        case Set_All_Report_For_Ceo:
            initialdata = { ...initialdata, reportScansRec: action.result }
            return initialdata
            break;
        default:
            return initialdata
            break;
    }
}

export const finalrecord = combineReducers({ AllReportForCeo, StaticsRecord, Details, UserDetailRecord, SuperAdminLoginData, handleUserStatusData,handleCompanyStatusData, CompanyAdminDetail, SuperAdminDetail, MailForwardData, CompanyStoreData, CompanyRecord, AdminLoginData, CompanyForgotPass, UpdatePassword, UpdateProfile, CompanyEmployeRecord, CompanyExpiredEmpRecord, CompanyEmployeStoreData, MultiUserStoreData, EmployeStoreData, EmployeRecord, CompanyAssistantStoreData, CompanyAssistantRecord })