import "react-toastify/dist/ReactToastify.css";
import Arrowdown from "assets/img/down-arrow.png";
import EmployeEditPop from "./EmployeEditPop";
import Footer from "components/SuperAdmin/Footer";
import React from "react";
import ReactPaginate from "react-paginate";
import Sidebar from "components/SuperAdmin/Sidebar";
import loader from "assets/img/loading.gif";
import { getAllEmployes, handleUserStatus, resetSuperAdminEmployeData, resetUserStatusData } from "Services/action";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const SuperEmployeList = () => {
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();
  const successNotify = (succesData) =>
    toast.success(succesData, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const handleButtonClick = (data) => {
    setIsModalOpen(true);
    setModalData(data);
  };
  
  const handleCloseModal = (employeData) => {
    setIsModalOpen(false);
    if(employeData){
      updateRecord(modalData.employeData.id, employeData)
    }
    setModalData({});
  };

  const updateRecord = (id, updatedData) => {
    setEmployeList(EmployeList => {
      const updatedList = EmployeList.map(item =>
        item.id === id ? { ...item, ...updatedData } : item
      );
      return updatedList;
    });
  }; 
  
  const userStatusUpdate = () =>
    toast.success("Status updated successfully !.", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const today = new Date(); // Current date

  const dispatch = useDispatch();
  const [EmployeList, setEmployeList] = useState([]);
  const [EmployeCsvList, setEmployeCsvList] = useState([]);
  const [Search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [expireDays, setExpireDays] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [dateFieldVisible, setDateFieldVisible] = useState(false);

  const perPageRecord = 10
  const handlePageClick = ({ selected }) => {
    const data = {
      name: Search,
      companyAssigned: 1,
      currentPage: selected,
      limit: perPageRecord
    }
    dispatch(getAllEmployes(data));
  }

  useEffect(() => {
    const data = {
      currentPage: 0, 
      companyAssigned: 1,
      limit: perPageRecord
    }
    dispatch(getAllEmployes(data));
    dispatch(resetSuperAdminEmployeData());
  }, []);


  const filterData = () => {

    let newStartDate = '';
    let newEndDate = '';
    if (expireDays != 0 && expireDays != '') {
      const currentDate = new Date(); // Current date

      const currentYear = currentDate.getFullYear();
      const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so add 1 and pad with '0' if needed
      const currentDay = String(currentDate.getDate()).padStart(2, '0'); // Pad day with '0' if needed
      newStartDate = `${currentYear}-${currentMonth}-${currentDay}`;

      var expDate = parseInt(expireDays);
      today.setDate(today.getDate() + expDate);
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so add 1 and pad with '0' if needed
      const day = String(today.getDate()).padStart(2, '0'); // Pad day with '0' if needed
      newEndDate = `${year}-${month}-${day}`;
    }else{
      newStartDate = startDate;
      newEndDate = endDate;
    }
  

    const data = {
      name: Search,
      status: status,
      startDate: newStartDate,
      endDate: newEndDate,
      currentPage: 0,
      companyAssigned: 1,
      limit: perPageRecord
    }
    dispatch(getAllEmployes(data));
  }

  const resetfilterData = () => {
    setSearch('')
    setStatus('')
    setExpireDays('')
    setStartDate('')
    setEndDate('')
    setDateFieldVisible(false)
    const data = {
      currentPage: 0,
      companyAssigned: 1,
      limit: perPageRecord
    }
    dispatch(getAllEmployes(data));
  }


  const initialdata = useSelector((state) => state?.EmployeRecord?.employeRec?.result?.list);
  const initialcsvdata = useSelector((state) => state?.EmployeRecord?.employeRec)?.result?.csvData;

  const totalCount = useSelector((state) => state?.EmployeRecord?.employeRec?.result?.count);
  const initialUserStatusdata = useSelector((state) => state.handleUserStatusData);
  const initialStoredata = useSelector((state) => state?.EmployeStoreData);
  const multiEmployeStoredata = useSelector((state) => state?.CompanyEmployeStoreData);
  useEffect(() => {
    if (initialStoredata?.success) {
      let succesData = initialStoredata?.message
      successNotify(succesData);
    }
    if (multiEmployeStoredata?.success) {
      let succesData = multiEmployeStoredata?.message
      successNotify(succesData);
    }
  }, [initialStoredata, multiEmployeStoredata]);
  const initialCompanyStoredata = useSelector(
    (state) => state.CompanyStoreData
  );
  useEffect(() => {
    if (initialCompanyStoredata?.success) {
      let succesData = initialCompanyStoredata?.message
      successNotify(succesData);
    }
  }, [initialCompanyStoredata]);

  useEffect(() => {
    if (initialUserStatusdata.success == true) {
      userStatusUpdate();
      dispatch(resetUserStatusData())
    }
  }, [initialUserStatusdata]);

  useEffect(() => {
    setEmployeList(initialdata);
    setEmployeCsvList(initialcsvdata);
    setLoading(true);

  }, [initialdata]);

  const handleSelectChange = (selectedOption, empId) => {
    const data = {
      id: empId,
      status: selectedOption
    }
    dispatch(handleUserStatus(data));
  };

  const handleOptionChange = (event) => {
    setStatus(event.target.value);
  };
  const handleDaysChange = (event) => {
    const selectedDays = event.target.value;
    setExpireDays(selectedDays);
    if (selectedDays && selectedDays === "0") {
      setDateFieldVisible(true)
    } else {
      setDateFieldVisible(false)
    }
  };

  useEffect(() => {
    const columns = [
      {
        FirstName: "FirstName",
        LastName: "LastName",
        PhoneNo: "PhoneNo",
        Company: "Company",
        Role: "Role",
        Card: "Card",
        ExpiryDate: "ExpireDate",
        Status: "Status",
      },
    ];
    setExcelData(columns);
  }, []);
  const getCsvData = () => {
    const csvData = [];
    if (EmployeCsvList?.length > 0) {
      excelData.map((ex) => {
        csvData.push([
          `${ex.FirstName}`,
          `${ex.LastName}`,
          `${ex.PhoneNo}`,
          `${ex.Company}`,
          `${ex.Role}`,
          `${ex.Card}`,
          `${ex.ExpiryDate}`,
          `${ex.Status}`,
        ]); 
      });
      EmployeCsvList?.map((val) => {
        const parts = val?.expiry_date?.split('-');
    
        // Check if 'parts' is defined and is an array
        if (Array.isArray(parts) && parts.length === 3) {
            const rearrangedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
            csvData.push([
                `${val.name}`,
                `${val.lastname}`,
                `${val.phone_no}`,
                `${val.company}`,
                `${val.role}`,
                `${val.card_no}`,
                `${rearrangedDate}`,
                `${val.status === 1 ? 'Active' : 'Inactive'}`,
            ]);
        } else {
            // Handle the case where 'parts' is undefined or not an array
            console.error("Invalid date format for:", val);
        }
    });
    
    }
    return csvData;
  };

  return (
    <>
      <ToastContainer />
      <Sidebar />
      <div className="md:ml-32">
        <div className="px-3 md:px-8 h-auto mt-16">
          <div className="container mx-auto max-w-full">
            <div className="grid grid-cols-1 px-4 mb-16">
              <div class="grid grid-cols-2 gap-4">

                <div className="mb-5">
                  <h2 className="head-cust-color">Workers - {totalCount && totalCount}</h2>
                </div>
                <div className="right-button-section">
                  <CSVLink filename="WorkerList.csv" data={getCsvData()} >
                    {" "}
                    <button className="cust-button mb-3 mr-5" style={{ display: "inline-flex" }}>Export CSV <img src={Arrowdown} className="w-4 h-4" style={{ margin: "5px 0px 2px 5px" }} /> </button>
                  </CSVLink>
                  <NavLink to="/superAdmin/addMultiEmploye">
                    <button className="cust-button mb-3 mr-5">Upload CSV +</button>
                  </NavLink>

                  <NavLink to="/superAdmin/addEmploye">
                    <button className="cust-button mb-3">Add +</button>
                  </NavLink>

                </div>
              </div>
              <hr class="my-4 min-w-full"></hr>
              <div class="flex flex-wrap mb-4 mt-4">
                <div className="lg:w-3/12 ">
                  <input type="text" className="cust-input" placeholder="Search" value={Search}
                    onChange={(e) => setSearch(e.target.value)} />
                </div>
                <div className="ml-2 mr-2 lg:w-1/12 ">
                  <select id="filterstatus" value={status} style={{ border: '1px solid #797979', borderRadius: "25px" }} className="filterinput w-full h-11 focus:outline-none"
                    onChange={handleOptionChange} >
                    <option value="">Status</option>
                    <option value="1">Active</option>
                    <option value="0">Deactive</option>
                  </select>
                </div>
                <div className="ml-2 mr-2 lg:w-1/12 ">
                  <select id="filterExpireDays" value={expireDays} style={{ border: '1px solid #797979', borderRadius: "25px" }} className="filterinput w-full h-11 focus:outline-none"
                    onChange={handleDaysChange} >
                    <option value="">Days</option>
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="60">60</option>
                    <option value="0">Select Date</option>
                  </select>
                </div>
                {dateFieldVisible && (
                  <>
                    <div className="ml-2 mr-2 lg:w-2/12 ">
                      <input type="date" placeholder="From Date:" className="filterinput w-full h-11 focus:outline-none" value={startDate}
                        onChange={(e) => setStartDate(e.target.value)} style={{ border: '1px solid #797979', borderRadius: "25px" }} />
                    </div>
                    <div className="ml-2 mr-2 lg:w-2/12">
                      <input type="date" placeholder="To Date:" className="filterinput w-full h-11 focus:outline-none" value={endDate}
                        onChange={(e) => setEndDate(e.target.value)} style={{ border: '1px solid #797979', borderRadius: "25px" }} />
                    </div>
                  </>
                )}
                <div className="lg:w-2/12 ">
                  <button className="cust-button mb-3 ml-5" onClick={filterData}>Apply</button>
                  <button className="cust-button mb-3 ml-5" onClick={resetfilterData}>Reset</button>

                </div>

              </div>
              <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr className="px-6 py-4 font-medium text-blue-600 whitespace-nowrap dark:text-white">
                    <th scope="col" class="px-6 py-3">
                      Name
                    </th>

                      <th scope="col" class="px-6 py-3">
                        Phone
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Role
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Card No.
                      </th>

                      <th scope="col" class="px-6 py-3">
                        Expire Date
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Status
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {EmployeList?.length && EmployeList[0]?.id != null ? (
                      EmployeList?.map((EmployeListVal, i) => {
                        const expiryDate = new Date(EmployeListVal?.expiry_date);
                        const timeDifference = expiryDate - today;
                        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                        return (
                          <tr
                            className={daysDifference <= 60 ? "text-black border-b bg-red-500 dark:bg-red-500 dark:border-gray-700" :
                              "text-black border-b bg-white dark:bg-gray-800 dark:border-gray-700"}
                            key={i}
                          >
                            <td className="px-6 py-4">
                              {EmployeListVal?.name}
                            </td>

                            <td class="px-6 py-4">
                              {EmployeListVal?.phone_no}
                            </td>
                            <td class="px-6 py-4">
                              {EmployeListVal?.role}
                            </td>
                            <td class="px-6 py-4">
                              {EmployeListVal?.card_no}
                            </td>
                            <td className="px-6 py-4">
                              {EmployeListVal?.expiry_date}
                            </td>
                            <td className="px-6 py-4">
                              <select onChange={(event) => handleSelectChange(event.target.value, EmployeListVal.id)}>
                                <option value="1" selected={EmployeListVal && EmployeListVal.status === 1}>Active</option>
                                <option value="0" selected={EmployeListVal && EmployeListVal.status === 0}>Deactive</option>
                              </select>
                            </td>
                            <td className="px-6 py-4">
                              <button className="cust-button" onClick={() => handleButtonClick({ employeData: EmployeListVal })}>Edit</button>
                              {/* <button className="cust-button" onClick={() => navigate('/superAdmin/editEmploye', { state: { employeData: EmployeListVal } })}>Edit</button> */}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 text-center" colSpan={7}>
                          No Record Found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {isModalOpen && (
                  <EmployeEditPop
                  employeData={modalData.employeData}
                  onClose={handleCloseModal}
                />
                )}
              </div>
              <ReactPaginate
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={totalCount / perPageRecord}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default SuperEmployeList;
