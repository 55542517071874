import { NavLink, useNavigate } from "react-router-dom";
import Button from "@material-tailwind/react/Button";
import Icon from "@material-tailwind/react/Icon";
import Dropdown from "@material-tailwind/react/Dropdown";
import DropdownItem from "@material-tailwind/react/DropdownItem";
import locationicon from "assets/img/location.png";
import { adminLogout } from "../../Services/action";
import { useDispatch, useSelector } from "react-redux";
import WebLogo from 'assets/img/logo.png';
import { useEffect, useState } from "react";

export default function AdminNavbar({ showSidebar, setShowSidebar }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [isActive, setIsActive] = useState(true);

  const handleUserActivity = () => {
    setIsActive(true);

    // Reset the timeout on user activity
    clearTimeout(timeoutId);
    startTimeout();
  };

  useEffect(() => {
    startTimeout();

    // Set up event listeners for user activity
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      // Clean up event listeners
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);

      // Clear the timeout on component unmount
      clearTimeout(timeoutId);
    };
  }, []);


  let timeoutId;

  const startTimeout = () => {
    timeoutId = setTimeout(() => {
      // Perform logout when the timeout is reached
      dispatch(adminLogout());
      navigate("/company");
    }, 3000000);
  };


  const companyAdminData = useSelector((state) => state?.CompanyAdminDetail?.companyAdminDetailRec);
  const logout = () => {
    dispatch(adminLogout());
    navigate("/company");
  };

  return (

    <nav className="custom-navbar md:ml-32 py-2 px-3">
      <div className="container max-w-full mx-auto flex items-center justify-between md:pr-8 md:pl-10">
        <div className="md:hidden">
          <Button
            color="transparent"
            buttonType="link"
            size="lg"
            iconOnly
            rounded
            ripple="light"
            onClick={() => setShowSidebar("left-0")}
          >
            <Icon name="menu" size="2xl" color="white" />
          </Button>
          <div
            className={`absolute top-2 md:hidden ${showSidebar === "left-0" ? "left-64" : "-left-64"
              } z-50 transition-all duration-300`}
          >
            <Button
              color="transparent"
              buttonType="link"
              size="lg"
              iconOnly
              rounded
              ripple="light"
              onClick={() => setShowSidebar("-left-64")}
            >
              <Icon name="close" size="2xl" color="white" />
            </Button>
          </div>
        </div>



        <div className="flexs justify-center items-center w-full text-center">
          <div className="flex justify-center items-center w-full">
          <img className="h-16" src={companyAdminData && companyAdminData?.companyLogo ? companyAdminData?.companyLogo : WebLogo} />
          </div>
          <h4 className="uppercase text-white text-2xl tracking-wider mt-1">
            {companyAdminData && companyAdminData?.name}
            
          </h4>
          </div>

          <div className="flex justify-end">
            
            <div className="-mr-4 ml-6 profile-button custom-profile">
              <Dropdown
                color="transparent"
                buttonText={
                  <div className="w-24 profile-section">
                    Company
                  </div>
                }
                rounded
                style={{
                  padding: 0,
                  color: "white",
                }}
              >
                <NavLink to="/company/settings"></NavLink>
                <div className='university profile-data'>
                {companyAdminData && companyAdminData?.memberName ? <> <p className="click-open-btn"><Icon name="person" size="1xl" color="black" />
                 <a href="#">{companyAdminData?.memberName}</a>
                 </p>
                  <p className="click-open-btn"><Icon name="phone" size="1xl" color="black" />
                 <a href="#">{companyAdminData?.memberPhone}</a>
                 </p> </> : <p className="click-open-btn"><Icon name="phone" size="1xl" color="black" />
                 <a href="#">{companyAdminData && companyAdminData?.phone_no}</a>
                 </p>}
                
                  <p className="click-open-btn"><img src={locationicon} className="location-img" /> <a href="#">{companyAdminData && companyAdminData?.city + ", " + companyAdminData?.state + ", " + companyAdminData?.country}</a></p>
                  <p className="sign-button-p">
                    <DropdownItem color="lightBlue" onClick={() => {
                      const confirmBox = window.confirm("Are you sure you want to logout?");
                      if (confirmBox === true) {
                        logout();
                      }
                    }}>Sign out</DropdownItem></p>
                </div>
              </Dropdown>
            </div>
          </div>
      </div>
    </nav>
  );
}
