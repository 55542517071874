import "react-multi-carousel/lib/styles.css";
import AppliedStudentsIcon from "assets/img/AppliedStudents.png";
import Card from "@material-tailwind/react/Card";
import CardRow from "@material-tailwind/react/CardRow";
import CardStatus from "@material-tailwind/react/CardStatus";
import Carousel from "react-multi-carousel";
import React, { useEffect, useState } from "react";
import StatusCard from "./StatusCard";
import StudentIcon from "assets/img/student.png";
import { getStatistics } from "Services/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";

const MainStatusCard = () => {
    const companyAdminData = useSelector((state) => state?.CompanyAdminDetail?.companyAdminDetailRec);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [TotalEmployee, setTotalEmployee] = useState(0)
    const [TotalActiveEmployee, setTotalActiveEmployee] = useState(0)
    const [TotalInActiveEmployee, setTotalInActiveEmployee] = useState(0)
    const [Expired15DaysEmployee, setExpired15DaysEmployee] = useState(0)
    const [Expired30DaysEmployee, setExpired30DaysEmployee] = useState(0)
    const [Expired60DaysEmployee, setExpired60DaysEmployee] = useState(0)
    const [TotalExpiredEmployee, setTotalExpiredEmployee] = useState(0)
    const [TotalAssistant, setTotalAssistant] = useState(0)

    useEffect(() => {
        const data = {
            id: companyAdminData?.id
        }
        dispatch(getStatistics(data))
    }, [])

    const StaticsRecord = useSelector((state) => state?.StaticsRecord?.staticsRec);
    useEffect(() => {
        setTotalEmployee(StaticsRecord?.totalCompanyEmploye)
        setTotalActiveEmployee(StaticsRecord?.totalActiveCompanyEmploye)
        setTotalInActiveEmployee(StaticsRecord?.totalInActiveCompanyEmploye)
        setExpired15DaysEmployee(StaticsRecord?.expiredFifteenDaysCompanyEmploye)
        setExpired30DaysEmployee(StaticsRecord?.expiredThirtyDaysCompanyEmploye)
        setExpired60DaysEmployee(StaticsRecord?.expiredSixtyDaysCompanyEmploye)


        setTotalExpiredEmployee(StaticsRecord?.totalExpiredCompanyEmploye)
        setTotalAssistant(StaticsRecord?.totalCompanyAssistant)


    }, [StaticsRecord])

    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const renderAdditionalContent = () => {
        if (!showMore) {
            return null;
        }

        return (
            <>
                {<div className="grid grid-cols-1 xl:grid-cols-6">
                {!companyAdminData?.hide_expired_workers ? ( 
                    <NavLink to="/company/expiredEmpList">
                <div className="px-6 mb-10"> Expired
                    <div style={{ backgroundColor: 'red', width: "20px", height: "20px" }}></div>
                    <span>{TotalExpiredEmployee && TotalExpiredEmployee ? TotalExpiredEmployee : 0}</span>
                </div>
                </NavLink>
                ) : null}
                <div className="px-6 mb-10"> 15 Days
                    <div style={{ backgroundColor: 'red', width: "20px", height: "20px" }}></div>
                    <span>{Expired15DaysEmployee && Expired15DaysEmployee ? Expired15DaysEmployee : 0}</span>
                </div>
                <div className="px-6 mb-10"> 30 Days
                    <div style={{ backgroundColor: 'orange', width: "20px", height: "20px" }}></div>
                    <span> {Expired30DaysEmployee && Expired30DaysEmployee ? Expired30DaysEmployee : 0}</span>
                </div>
                <div className="px-6 mb-10"> 60 Days<div style={{ backgroundColor: 'yellow', width: "20px", height: "20px" }}></div>
                    <span>{Expired60DaysEmployee && Expired60DaysEmployee ? Expired60DaysEmployee : 0}</span>
                </div>
                <div className="px-6 mb-10"> Active
                    <div style={{ backgroundColor: 'green', width: "20px", height: "20px" }}></div>
                    <span>{TotalActiveEmployee && TotalActiveEmployee ? TotalActiveEmployee : 0}</span>
                </div>
                <div className="px-6 mb-10"> InActive
                    <div style={{ backgroundColor: 'grey', width: "20px", height: "20px" }}></div>
                    <span>{TotalInActiveEmployee && TotalInActiveEmployee ? TotalInActiveEmployee : 0}</span>
                </div>
            </div>}
            </>
        );
    };

    return (
        <>
            <Carousel responsive={responsive}>
                <div className="px-6 mb-10 main-tiles-section">
                    <NavLink to="/company/employeList">
                        <Card className="main-tiles p-0">
                            <CardRow className="inner-tiles">
                                {companyAdminData?.hide_expired_workers ? (<CardStatus className="tiles-title" title={TotalEmployee && TotalEmployee ? TotalEmployee-TotalExpiredEmployee : 0} />) : 
                                    (<CardStatus className="tiles-title" title={TotalEmployee && TotalEmployee ? TotalEmployee : 0} />)
                                }
                                <img src={StudentIcon} className="w-24 h-24" />
                                <CardStatus className="tiles-title-bottom" title={"Workers"} />
                            </CardRow>
                        </Card>
                    </NavLink>
                </div>
                <div className="px-6 mb-10 main-tiles-section">
                    <NavLink to="/company/assistantList">
                        <Card className="main-tiles p-0">
                            <CardRow className="inner-tiles">
                                <CardStatus className="tiles-title" title={TotalAssistant && TotalAssistant ? TotalAssistant : 0} />
                                <img src={AppliedStudentsIcon} className="w-24 h-24" />
                                <CardStatus className="tiles-title-bottom" title={"Team Members"} />
                            </CardRow>
                        </Card>
                    </NavLink>
                </div>
            </Carousel>
            {renderAdditionalContent()}
            <div className="px-6 mb-10">
                <button style={{ color: '#07658b', marginLeft: '4px' }} onClick={toggleShowMore}>
                    {showMore ? "Show Less" : "Show More"}
                </button>
            </div>
        </>
    )
}
export default MainStatusCard