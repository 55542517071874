//export const API_URL = "http://localhost:6500/api/"; 
export const API_URL = "https://backend.tradieid.net.au/api/";
export const GETDETAILS = 'GETDETAILS'; 
export const Get_Statistics = 'Get_Statistics'; 
export const Set_Statistics = 'Set_Statistics';

export const Get_All_Report_For_Ceo = 'Get_All_Report_For_Ceo';
export const Set_All_Report_For_Ceo = 'Set_All_Report_For_Ceo';

export const Get_User_Detail = 'Get_User_Detail'; 
export const Set_User_Detail = 'Set_User_Detail'; 

export const Handle_Company_Status = 'Handle_Company_Status';
export const Set_Handle_Company_Status_Data = 'Set_Handle_Company_Status_Data';
export const Set_Handle_Company_Status_Data_Fail = 'Set_Handle_Company_Status_Data_Fail';
export const Reset_Company_Status_Data = 'Reset_Company_Status_Data';
export const Set_Reset_Company_Status_Data = 'Set_Reset_Company_Status_Data';

export const Handle_User_Status = 'Handle_User_Status';
export const Set_Handle_User_Status_Data = 'Set_Handle_User_Status_Data';
export const Set_Handle_User_Status_Data_Fail = 'Set_Handle_User_Status_Data_Fail';
export const Reset_User_Status_Data = 'Reset_User_Status_Data';
export const Set_Reset_User_Status_Data = 'Set_Reset_SuperAdmin_Employe_Data';

export const Get_Local_Store_Data = 'Get_Local_Store_Data';
export const set_Local_Store_Data = "set_Local_Store_Data";

export const Store_Multi_User = 'Store_Multi_User';
export const Set_Store_Multi_User_Data = 'Set_Store_Multi_User_Data';
export const Set_Store_Multi_User_Data_Fail = 'Set_Store_Multi_User_Data_Fail';


export const Store_Multi_Employe_Request = 'Store_Multi_Employe_Request';

export const Set_Store_Multi_Employe_Data = 'Set_Store_Multi_Employe_Data';
export const Set_Store_Multi_Employe_Data_Fail = 'Set_Store_Multi_Employe_Data_Fail';



//Superadmin Section Start
export const SuperAdminUserLogin = 'SuperAdminUserLogin';
export const SuperAdminUserLogout = 'SuperAdminUserLogout';
export const Get_SuperAdmin_Local_Store_Data = 'Get_SuperAdmin_Local_Store_Data';
export const set_SuperAdmin_Local_Store_Data = "set_SuperAdmin_Local_Store_Data";
export const Set_SuperAdmin_Login = 'Set_SuperAdmin_Login'; 
export const SuperAdmin_Login_Fail = 'SuperAdmin_Login_Fail';
export const Reset_SuperAdmin_Login_Data ='Reset_SuperAdmin_Login_Data';
export const Set_Reset_SuperAdmin_Login_Data ='Set_Reset_SuperAdmin_Login_Data';
export const Get_Super_Admin_Details ='Get_Super_Admin_Details';
export const Set_Super_Admin_Details ='Set_Super_Admin_Details';



export const Set_SuperAdmin_Logout = 'Set_SuperAdmin_Logout';


//Superadmin Company Section Start

export const Store_Company = 'Store_Company';
export const Store_Company_Request = 'Store_Company_Request';
export const Set_Store_Company_Data = 'Set_Store_Company_Data';
export const Set_Store_Company_Data_Fail = 'Set_Store_Company_Data_Fail';
export const Reset_Company_Data = 'Reset_Company_Data';
export const Set_Reset_Company_Data = 'Set_Reset_Company_Data';
export const Set_Store_Company_Data_Already_Exist = 'Set_Store_Company_Data_Already_Exist';

//Mail Forward Multiple Employe
export const Mail_Forward = 'Mail_Forward';
export const Mail_Forward_Request = 'Mail_Forward_Request';
export const Set_Mail_Forward_Data = 'Set_Mail_Forward_Data';
export const Set_Mail_Forward_Data_Fail = 'Set_Mail_Forward_Data_Fail';
export const Reset_Mail_Forward_Data = 'Reset_Mail_Forward_Data';
export const Set_Reset_Mail_Forward_Data = 'Set_Reset_Mail_Forward_Data';




export const Update_Company = 'Update_Company';
export const Update_Company_Request = 'Update_Company_Request';
export const Set_Update_Company_Data = 'Set_Update_Company_Data';
export const Set_Update_Company_Data_Fail = 'Set_Update_Company_Data_Fail';

export const Get_Company = 'Get_Company';
export const Set_Company_List = 'Set_Company_List';
//Superadmin Company Section End


//Superadmin Employe Section Start

export const Store_SuperAdmin_Employe = 'Store_SuperAdmin_Employe';
export const Store_SuperAdmin_Employe_Request = 'Store_SuperAdmin_Employe_Request';
export const Set_Store_SuperAdmin_Employe_Data = 'Set_Store_SuperAdmin_Employe_Data';
export const Set_Store_SuperAdmin_Employe_Data_Fail = 'Set_Store_SuperAdmin_Employe_Data_Fail';
export const Reset_SuperAdmin_Employe_Data = 'Reset_SuperAdmin_Employe_Data';
export const Set_Reset_SuperAdmin_Employe_Data = 'Set_Reset_SuperAdmin_Employe_Data';


export const Update_SuperAdmin_Employe = 'Update_SuperAdmin_Employe';
export const Update_SuperAdmin_Employe_Request = 'Update_SuperAdmin_Employe_Request';
export const Set_Update_SuperAdmin_Employe_Data = 'Set_Update_SuperAdmin_Employe_Data';
export const Set_Update_SuperAdmin_Employe_Data_Fail = 'Set_Update_SuperAdmin_Employe_Data_Fail';

export const Get_All_Employes = 'Get_All_Employes';
export const Set_All_Employes = 'Set_All_Employes';
//Superadmin Employe Section End


//Superadmin Section End

//Company Admin Section Start

export const AdminUserLogin = 'AdminUserLogin';
export const Reset_Login_Data ='Reset_Login_Data';
export const Set_Reset_Login_Data ='Set_Reset_Login_Data';

export const Company_Forgot_Pass = 'Company_Forgot_Pass';
export const Company_Forgot_Pass_Request = 'Company_Forgot_Pass_Request';
export const Set_Company_Forgot_Pass_Data = 'Set_Company_Forgot_Pass_Data';
export const Set_Company_Forgot_Pass_Data_Fail = 'Set_Company_Forgot_Pass_Data_Fail';

export const Reset_Company_Forgot_Pass_Data ='Reset_Company_Forgot_Pass_Data';
export const Set_Reset_Company_Forgot_Pass_Data ='Set_Reset_Company_Forgot_Pass_Data';

export const Update_Password = 'Update_Password';
export const Update_Password_Request = 'Update_Password_Request';
export const Set_Update_Password_Data = 'Set_Update_Password_Data';
export const Set_Update_Password_Data_Fail = 'Set_Update_Password_Data_Fail';

export const Reset_Update_Password_Data ='Reset_Update_Password_Data';
export const Set_Reset_Update_Password_Data ='Set_Reset_Update_Password_Data';



export const Get_Company_Admin_Details ='Get_Company_Admin_Details';
export const Set_Company_Admin_Details ='Set_Company_Admin_Details';


export const Update_Profile = 'Update_Profile';
export const Set_Update_Profile_Request = 'Set_Update_Profile_Request';
export const Set_Update_Profile_Data = 'Set_Update_Profile_Data';
export const Set_Update_Profile_Fail = 'Set_Update_Profile_Fail';
export const Reset_Update_Profile_Data = 'Reset_Update_Profile_Data';
export const Set_Reset_Update_Profile_Data = 'Set_Reset_Update_Profile_Data';


export const Set_Admin_Login = 'Set_Admin_Login'; 
export const Admin_Login_Invalid = 'Admin_Login_Invalid';
export const Admin_Login_Fail = 'Admin_Login_Fail';
export const AdminUserLogout = 'AdminUserLogout';
export const Set_Admin_Logout = 'Set_Admin_Logout';


export const Store_CompanyAdmin_Employe = 'Store_CompanyAdmin_Employe';
export const Store_CompanyAdmin_Employe_Request = 'Store_CompanyAdmin_Employe_Request';
export const Set_Store_CompanyAdmin_Employe_Data = 'Set_Store_CompanyAdmin_Employe_Data';
export const Set_Store_CompanyAdmin_Employe_Data_Fail = 'Set_Store_CompanyAdmin_Employe_Data_Fail';

export const Update_CompanyAdmin_Employe = 'Update_CompanyAdmin_Employe';
export const Update_CompanyAdmin_Employe_Request = 'Update_CompanyAdmin_Employe_Request';
export const Set_Update_CompanyAdmin_Employe_Data = 'Set_Update_CompanyAdmin_Employe_Data';
export const Set_Update_CompanyAdmin_Employe_Data_Fail = 'Set_Update_CompanyAdmin_Employe_Data_Fail';


export const Get_Employe_By_Company = 'Get_Employe_By_Company';
export const Set_Employe_By_Company = 'Set_Employe_By_Company';

export const Reset_CompanyAdmin_Employe_Data = 'Reset_CompanyAdmin_Employe_Data';
export const Set_Reset_CompanyAdmin_Employe_Data = 'Set_Reset_CompanyAdmin_Employe_Data';

export const Get_Expired_Emp_By_Company = 'Get_Expired_Emp_By_Company';
export const Set_Expired_Emp_By_Company = 'Set_Expired_Emp_By_Company';

export const Reset_Company_Expired_Emp_Data = 'Reset_Company_Expired_Emp_Data';
export const Set_Reset_Company_Expired_Emp_Data = 'Set_Reset_Company_Expired_Emp_Data';

export const Get_Assistant_By_Company = 'Get_Assistant_By_Company';
export const Set_Assistant_By_Company = 'Set_Assistant_By_Company';

export const Store_Company_Assistant = 'Store_Company_Assistant';
export const Store_Company_Assistant_Request = 'Store_Company_Assistant_Request';
export const Set_Store_Company_Assistant_Data = 'Set_Store_Company_Assistant_Data';
export const Set_Store_Company_Assistant_Data_Fail = 'Set_Store_Company_Assistant_Data_Fail';

export const Update_Company_Assistant = 'Update_Company_Assistant';
export const Update_Company_Assistant_Request = 'Update_Company_Assistant_Request';
export const Set_Update_Company_Assistant_Data = 'Set_Update_Company_Assistant_Data';
export const Set_Update_Company_Assistant_Data_Fail = 'Set_Update_Company_Assistant_Data_Fail';


export const Reset_Company_Assistant_Data = 'Reset_Company_Assistant_Data';
export const Set_Reset_Company_Assistant_Data = 'Set_Reset_Company_Assistant_Data';
export const Delete_Company_Assistant = 'Delete_Company_Assistant';