import Sidebar from "components/SuperAdmin/Sidebar";
import Footer from "components/SuperAdmin/Footer";
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import { updateCompany, resetCompanyData } from "Services/action";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import loader from "assets/img/loading.gif";

const EditCompany = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const companyDetail = location?.state?.companyData;

    const inputFile = useRef(null);
    const [loading, setLoading] = useState(false);
    const [base64Image, setBase64Image] = useState('');
    const [logoImg, setlogoImg] = useState();

    const handleImageChange = (e) => {
        setlogoImg(URL.createObjectURL(e.target.files[0]));

        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64Image(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const errorNotify = (error) =>
        toast.error(error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();


    function onSubmit(companyData) {
        companyData.id = companyDetail?.id;
        companyData.companyLogo = base64Image;
        dispatch(resetCompanyData());
        dispatch(updateCompany(companyData));
    }
    
    const validatePassword = (password) => {
        const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;    
        return strongPasswordRegex.test(password);
    };

    const initialStoredata = useSelector((state) => state.CompanyStoreData);
    useEffect(() => {

        if (initialStoredata.store_request == true) {
            setLoading(true);
        }
        if (initialStoredata.success == true) {
            navigate('/superAdmin/companies')
        }
        if (initialStoredata?.error) {
            setLoading(false);
            let errorData = initialStoredata?.message
            errorNotify(errorData);
        }
    }, [initialStoredata])


    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="md:ml-32">
                <div className="px-3 md:px-8 h-auto mt-16">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 xl:grid-cols-6">
                            <div className="xl:col-start-1 xl:col-end-7 px-4 mb-16">
                                <div>
                                    <h2 className="head-cust-color">Edit Company</h2>
                                </div>
                                <Card className="bgcustsky rounded-none">
                                    <CardBody>
                                        <form className="custom-form form-student-set" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="flex flex-wrap mt-10">
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="Name">
                                                            Name
                                                        </label>
                                                        <input {...register("name", { required: true })} defaultValue={companyDetail?.name} placeholder="Name:" required className="w-full h-full focus:outline-none" />
                                                        {errors.name && <span className="error">Name is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="password">
                                                            Please enter a strong password  (<a href="javascript:void(0)" title="Password must be at least 8 characters, must contain a number, a smaller case letter and an uppercase letter and a special character.">?</a>)
                                                        </label>
                                                        <input type="password" {...register("password", {
                                                            required: true,
                                                            minLength: 8,
                                                            validate: validatePassword
                                                        })} defaultValue={companyDetail?.originalPassword} placeholder="Password" required className="w-full h-full focus:outline-none" />
                                                        {errors.password && (
                                                            <span className="error">
                                                            {errors.password.type === "required"
                                                                ? "Password is required."
                                                                : "Password must be at least 8 characters, must contain a number, a smaller case letter and an uppercase letter and a special character."}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="Name">
                                                            License
                                                        </label>
                                                        <input {...register("license", { required: true })} defaultValue={companyDetail?.license} placeholder="License:" required className="w-full h-full focus:outline-none" />
                                                        {errors.license && <span className="error">License is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="phone_no">
                                                            Phone No.
                                                        </label>
                                                        <input type="number"
                                                            defaultValue={companyDetail?.phone_no} redonly disabled className="w-full h-full remove-arrow focus:outline-none"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="city">
                                                            City
                                                        </label>
                                                        <input type="text" {...register("city", { required: true })} defaultValue={companyDetail?.city} placeholder="City:" required className="w-full h-full focus:outline-none" />
                                                        {errors.city && <span className="error"> City is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="state">
                                                            State
                                                        </label>
                                                        <input type="text" {...register("state", { required: true })} defaultValue={companyDetail?.state} placeholder="State:" required className="w-full h-full focus:outline-none" />
                                                        {errors.state && <span className="error">  State is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="country">
                                                            Country
                                                        </label>
                                                        <input type="text" {...register("country", { required: true })} defaultValue={companyDetail?.country} placeholder="Country:" required className="w-full h-full focus:outline-none" />
                                                        {errors.country && <span className="error"> Country is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-12/12 pl-4 mb-10 font-light">
                                                    <div class="w-full relative">

                                                        {companyDetail?.companyLogo ? <img src={companyDetail?.companyLogo} style={{ width: "100px", height: "100px", float: "left", marginRight: "20px" }} /> : null}
                                                        {logoImg ? <img src={logoImg} style={{ width: "100px", height: "100px", float: "left", marginRight: "20px" }} /> : null}

                                                    </div>

                                                </div>
                                                <div className="w-full lg:w-12/12 pl-4 mb-10 font-light">
                                                    <div class="w-full relative">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="companyLogo">
                                                            Company Logo
                                                        </label>
                                                        <input type="file" className="mt-2" accept="image/*" onChange={handleImageChange} />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light btn-w-full">
                                                    <Button className="form-button" type="submit" disabled={loading && loading == true} >{loading && loading == true ? <img style={{ width: "25px", marginRight: "15px" }} src={loader} /> : null}
                                                        Submit</Button>                                                 </div>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
export default EditCompany
