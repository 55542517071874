import Sidebar from "components/SuperAdmin/Sidebar";
import Footer from "components/SuperAdmin/Footer";
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import { storeMultiUser, resetSuperAdminEmployeData, getCompany } from "Services/action";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import loader from "assets/img/loading.gif";
import Select from "react-select";
import attachcolor from "assets/img/attach-black.png";
import Papa from 'papaparse';

const AddMultiEmploye = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const inputFile = useRef(null);
    const [loading, setLoading] = useState(false);
    const [BatchFile, setBatchFile] = useState();
    const [SelectedCompany, setSelectedCompany] = React.useState();


    const errorNotify = (error) =>
        toast.error(error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const handleChange = (e) => {
        const batchFileObj = e.target.files[0];
        e.target.value = null;
        setBatchFile(batchFileObj);
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();


    function onSubmit(eData) {

        const companyIds = SelectedCompany?.id?.toString().split(",");
        let employeData = [];
        Papa.parse(BatchFile, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                results.data.map((employeRec) => {
                    const phoneNo = employeRec?.PhoneNo || ""; // Handle the case when PhoneNo is undefined
                    const paddedPhoneNo = phoneNo.padStart(10, '0');

                    const parts = employeRec?.ExpireDate.split('-'); // Split the original date string into an array [day, month, year]

                    const rearrangedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
                    const arr = {}
                    arr.name = employeRec?.Name
                    arr.companies = companyIds
                    arr.phone_no = paddedPhoneNo
                    arr.role = employeRec?.Role
                    arr.card_no = employeRec?.CardNo
                    arr.expiry_date = rearrangedDate
                    arr.profileImage = employeRec?.ProfileImage  
                    employeData.push(arr)

                  });
                dispatch(storeMultiUser(employeData));

            },
        });

    }


    const initialStoredata = useSelector((state) => state?.EmployeStoreData);
    useEffect(() => {

        if (initialStoredata?.store_request == true) {
            setLoading(true);
        }
        if (initialStoredata?.success == true) {
            navigate('/superAdmin/employeList')
        }
        if (initialStoredata?.error) {
            setLoading(false);
            let errorData = initialStoredata?.message
            errorNotify(errorData);
        }
    }, [initialStoredata])

    useEffect(() => {
        dispatch(getCompany());
    }, []);
    const companyList = useSelector((state) => state?.CompanyRecord?.companyRec?.result?.list);

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="md:ml-32">
                <div className="px-3 md:px-8 h-auto mt-16">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 xl:grid-cols-6">
                            <div className="xl:col-start-1 xl:col-end-7 px-4 mb-16">
                                <div>
                                    <h2 className="head-cust-color">Add Multiple Workers</h2>
                                </div>
                                <Card className="bgcustsky rounded-none">
                                    <CardBody>
                                        <form className="custom-form form-student-set" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="flex flex-wrap mt-10">

                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <Select
                                                            className="block border-gray-part py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer border_two2"
                                                            options={companyList && companyList}
                                                            placeholder={"Comapny name :"}
                                                            value={SelectedCompany}
                                                            onChange={setSelectedCompany}
                                                            getOptionValue={(SelectedCompany) => SelectedCompany?.id}
                                                            getOptionLabel={(SelectedCompany) => `${SelectedCompany?.name}`}
                                                            required={true}
                                                            name="companies"
                                                        />
                                                    </div>

                                                </div>


                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11 add-student-part" onClick={() => inputFile.current.click()}>
                                                        <input className="addBatchInput" type="file"
                                                            accept=".csv"
                                                            onChange={handleChange}
                                                            ref={inputFile} />
                                                        {/* {loading && loading == true ? <div className="loader-image-set">
                                                            <img className="loaderImg" src={loader} />
                                                        </div> : null} */}
                                                        <input type="text" placeholder="Upload Workers List" className="w-full h-full focus:outline-none" />
                                                        <img src={attachcolor} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light btn-w-full">
                                                <Button className="form-button" type="submit" disabled={loading && loading == true} >{loading && loading == true ? <img style={{ width: "25px", marginRight: "15px" }} src={loader} /> : null}
                                                        Submit</Button>                                                </div>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
export default AddMultiEmploye
