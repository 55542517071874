import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { adminLogin, getLocalStoreData,getCompanyAdminDetails, resetUpdatePasswordData } from "../../Services/action";
import Button from '@material-tailwind/react/Button';
import { useForm } from "react-hook-form";
import ProfilePicture from 'assets/img/logo.png';
import Image from '@material-tailwind/react/Image';
import { ToastContainer, toast } from "react-toastify";

const AdminLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        dispatch(getLocalStoreData())
    }, [])

    const initialUpdatePasswordData = useSelector((state) => state?.UpdatePassword);


    const initialdata = useSelector((state) => state?.AdminLoginData);
    let companyToken = initialdata?.admintoken;

    function decodeJwt(token) {
        if (!token) {
            throw new Error('No token provided');
        }

        const tokenParts = token.split('.');
        if (tokenParts.length !== 3) {
            throw new Error('Invalid token format');
        }

        const base64Url = tokenParts[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        return JSON.parse(jsonPayload);
    }

    useEffect(() => {
        if (companyToken != '' && companyToken != null && companyToken !== "undefined") {
            const decodedCompanyToken = decodeJwt(companyToken);

              let data = '';
            if(decodedCompanyToken?.role == 2){
                 data = {
                    id: decodedCompanyToken?.created_by,
                    role: decodedCompanyToken?.role,
                    currentUserId:decodedCompanyToken?.userId
                }
            }else{
             data = {
                id: decodedCompanyToken?.userId
            }
        }
            dispatch(getCompanyAdminDetails(data));
            navigate('/company/dashboard');
        }
    },[companyToken])
    useEffect(() => {
        if (initialdata?.error) {
            let error = initialdata.error;
            errorNotify(error);
        }
    }, [initialdata])

    useEffect(() => {
        if (initialUpdatePasswordData?.success) {
            let succesData = initialUpdatePasswordData?.message
                successNotify(succesData);
                dispatch(resetUpdatePasswordData());
        }
    }, [initialUpdatePasswordData])

    

    function onSubmit(data) {
        dispatch(adminLogin(data));
    }

    const errorNotify = (error) =>
        toast.error(error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

        const successNotify = (succesData) =>
        toast.success(succesData, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    return (
        <>
            <ToastContainer />
            <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div>
                        <Image className="mx-auto h-12 w-1/4" src={ProfilePicture} />
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Company Login</h2>
                    </div>
                    <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-wrap mt-10">
                            <div className="w-full lg:w-12/12 mb-10 font-light">
                                <div class="w-full relative h-11">
                                    <label className="form-label">Phone Number</label>
                                    <input {...register("phone_no", { required: true })} placeholder="Phone No" className="w-full h-full focus:outline-none border border-gray-300 focus:border-indigo-500" />
                                    {errors.phone_no && <span className="error"> Phone No is required.</span>}
                                </div>
                            </div>
                            <div className="w-full lg:w-12/12 mb-10 font-light">
                                <div class="w-full relative h-11">
                                    <label className="form-label">Password</label>
                                    <input type="password" {...register("password", { required: true })} placeholder="Password" className="w-full h-full focus:outline-none border border-gray-300 focus:border-indigo-500" />
                                    {errors.password && <span className="error"> Password is required.</span>}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="flex">
                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light btn-w-full">
                                    <Button className="form-button " type="submit">Sign in</Button>
                                </div>
                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light btn-w-full">
                                <NavLink to="/company/forgotPassword">
                                    <Button className="form-button " type="submit">Forgot Passward</Button>
                                </NavLink>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
export default AdminLogin

