import Sidebar from "components/SuperAdmin/Sidebar";
import Footer from "components/SuperAdmin/Footer";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { getAllEmployes, resetSuperAdminEmployeData, handleUserStatus, resetUserStatusData } from "Services/action";
import ReactPaginate from "react-paginate";
import loader from "assets/img/loading.gif";

const SuperSelfRegisteredEmployeList = () => {
    const dataFetchedRef = useRef(false);
    const navigate = useNavigate();
    const successNotify = (succesData) =>
        toast.success(succesData, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true, 
            progress: undefined,
            theme: "light",
        });

    const userStatusUpdate = () =>
        toast.success("Status updated successfully !.", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const dispatch = useDispatch();
    const [EmployeList, setEmployeList] = useState([]);
    const [Search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [SelectedCompany, setSelectedCompany] = React.useState();

    const perPageRecord = 10
    const handlePageClick = ({ selected }) => {
        const data = {
            name: Search,
            companyAssigned: 0,
            currentPage: selected,
            limit: perPageRecord,
        }
        dispatch(getAllEmployes(data));
    }

    useEffect(() => {
        const data = {
            currentPage: 0,
            companyAssigned: 0,
            limit: perPageRecord,
        }
        dispatch(getAllEmployes(data));
        dispatch(resetSuperAdminEmployeData());
    }, []);


    const filterData = () => {

        const data = {
            name: Search,
            currentPage: 0,
            companyAssigned: 0,
            limit: perPageRecord,

        }
        dispatch(getAllEmployes(data));
    }

    const resetfilterData = () => {
        setSearch('')

        const data = {
            currentPage: 0,
            companyAssigned: 0,
            limit: perPageRecord,

        }
        dispatch(getAllEmployes(data));
    }

    

    const initialdata = useSelector((state) => state?.EmployeRecord?.employeRec?.result?.list);
    const totalCount = useSelector((state) => state?.EmployeRecord?.employeRec?.result?.count);
    const initialUserStatusdata = useSelector((state) => state?.handleUserStatusData);
    const initialStoredata = useSelector((state) => state?.EmployeStoreData);
    const multiEmployeStoredata = useSelector((state) => state?.CompanyEmployeStoreData);
    useEffect(() => {
        if (initialStoredata?.success) {
            let succesData = initialStoredata?.message
            successNotify(succesData);
        }
        if (multiEmployeStoredata?.success) {
            let succesData = multiEmployeStoredata?.message
            successNotify(succesData);
        }
    }, [initialStoredata, multiEmployeStoredata]);
    const initialCompanyStoredata = useSelector(
        (state) => state.CompanyStoreData
    );
    useEffect(() => {
        if (initialCompanyStoredata?.success) {
            let succesData = initialCompanyStoredata?.message
            successNotify(succesData);
        }
    }, [initialCompanyStoredata]);

    useEffect(() => {
        if (initialUserStatusdata?.success == true) {
            userStatusUpdate();
            dispatch(resetUserStatusData())
        }
    }, [initialUserStatusdata]);

    useEffect(() => {
        setEmployeList(initialdata);
        setLoading(true);

    }, [initialdata]);

    const handleSelectChange = (selectedOption, empId) => {
        const data = {
            id: empId,
            empStatus: selectedOption
        }
        dispatch(handleUserStatus(data));
    };


    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="md:ml-32">
                <div className="px-3 md:px-8 h-auto mt-16">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 px-4 mb-16">
                            <div className="mb-5">
                                <h2 className="head-cust-color">Self Registered Workers - {totalCount && totalCount}</h2>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <input type="text" className="cust-input" placeholder="Search" value={Search}
                                        onChange={(e) => setSearch(e.target.value)} />
                                    <button className="cust-button mb-3 ml-5" onClick={filterData}>Apply</button>
                                    <button className="cust-button mb-3 ml-5" onClick={resetfilterData}>Reset</button>

                                </div>
                                <div className="right-button-section">

                                </div>
                            </div>
                            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <tr className="px-6 py-4 font-medium text-blue-600 whitespace-nowrap dark:text-white">
                                            <th scope="col" className="px-6 py-3">
                                                Name
                                            </th>
                                           
                                            <th scope="col" className="px-6 py-3">
                                                Phone
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Status
                                            </th>
                                            {/* <th scope="col" className="px-6 py-3">
                                                Action
                                            </th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {EmployeList && EmployeList?.length && EmployeList[0]?.id != null  ? EmployeList?.map((EmployeListVal, i) => (
                                            <tr class={i / 2 === 0 ? "text-black border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700" : "text-black border-b bg-white dark:bg-gray-800 dark:border-gray-700"} key={i}>
                                                <td className="px-6 py-4">
                                                    {EmployeListVal?.name}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {EmployeListVal?.phone_no}
                                                </td>
                                                <td className="px-6 py-4">
                                                    <select onChange={(event) => handleSelectChange(event.target.value, EmployeListVal.id)}>
                                                        <option value="1" selected={EmployeListVal && EmployeListVal.status === 1}>Active</option>
                                                        <option value="0" selected={EmployeListVal && EmployeListVal.status === 0}>Deactive</option>
                                                    </select>
                                                </td>

                                                {/* <td className="px-6 py-4">
                                                    <button className="cust-button" onClick={() => navigate('/superAdmin/editEmploye', { state: { employeData: EmployeListVal } })}>Edit</button>
                                                </td> */}
                                            </tr>
                                        )) :
                                            <tr className="bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                <td className="px-6 py-4 text-center" colSpan={4}>
                                                    No Record Found.
                                                </td>
                                            </tr>}
                                    </tbody>
                                </table>
                            </div>
                            <ReactPaginate
                                nextLabel="next >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={totalCount / perPageRecord}
                                previousLabel="< previous"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};
export default SuperSelfRegisteredEmployeList;
