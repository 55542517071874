import Sidebar from "components/Company/Sidebar";
import Footer from "components/Company/Footer";
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import { updateCompanyAssistant, resetCompanyAssistantData } from "Services/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import loader from "assets/img/loading.gif";

const AssistantEdit = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const assistantDetail = location?.state?.employeData;
    const [loading, setLoading] = useState(false);
    
    const errorNotify = (error) =>
        toast.error(error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();


    function onSubmit(assistantData) {
        assistantData.id = assistantDetail?.id;
        dispatch(updateCompanyAssistant(assistantData));
        dispatch(resetCompanyAssistantData());

    }
    
    const validatePassword = (password) => {
      if (!password) {
        return true;
      }
    
      const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      return strongPasswordRegex.test(password);
    };

    const initialStoredata = useSelector((state) => state?.CompanyAssistantStoreData);


    useEffect(() => {

        if (initialStoredata?.store_request == true) {
            setLoading(true);
        }
        if (initialStoredata?.success == true) {
            navigate('/company/assistantList')
        }
        if (initialStoredata?.error) {
            setLoading(false);
            let errorData = initialStoredata?.message
            errorNotify(errorData);
        }
    }, [initialStoredata])
    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="md:ml-32">
                <div className="px-3 md:px-8 h-auto mt-16">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 xl:grid-cols-6">
                            <div className="xl:col-start-1 xl:col-end-7 px-4 mb-16">
                                <div>
                                    <h2 className="head-cust-color">Edit Team Member</h2>
                                </div>
                                <Card className="bgcustsky rounded-none">
                                    <CardBody>
                                        <form className="custom-form form-student-set" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="flex flex-wrap mt-2">
                                                <div className="w-full lg:w-4/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="Name">
                                                            Name
                                                        </label>
                                                        <input {...register("name", { required: true })}
                                                            defaultValue={assistantDetail?.name}
                                                            placeholder="Name:" required className="w-full h-full focus:outline-none" />
                                                        {errors.name && <span className="error"> Name is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-4/12 pl-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="phone_no">
                                                            Phone
                                                        </label>
                                                        <input type="number" {...register("phone_no", {required: true, minLength: 8, maxLength: 10})} 
                                                            defaultValue={assistantDetail?.phone_no}
                                                            placeholder="Phone:" required className="w-full h-full remove-arrow focus:outline-none"
                                                            onInput={(e) => {
                                                                if (e.target.value.length > 10) {
                                                                    e.target.value = e.target.value.slice(0, 10); // Truncate the value to 10 characters
                                                                }
                                                            }} />
                                                        {errors.phone_no && <span className="error"> Phone is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-4/12 pl-4 mb-10 font-light">
                                                  <div className="w-full relative h-11">
                                                    <label className="block text-black-700 text-sm font-bold ml-4" htmlFor="password">
                                                      Please enter a strong password (<a href="javascript:void(0)" title="Password must be at least 8 characters, must contain a number, a smaller case letter and an uppercase letter and a special character.">?</a>)
                                                    </label>
                                                    <input
                                                      type="text"
                                                      {...register("password", {
                                                        validate: {
                                                          optional: (value) => validatePassword(value)
                                                        }
                                                      })}
                                                      defaultValue={assistantDetail?.originalPassword}
                                                      placeholder="Password"
                                                      className="w-full h-full focus:outline-none"
                                                    />
                                                    {errors.password && (
                                                      <span className="error">
                                                        Password must be at least 8 characters, must contain a number, a lowercase letter, and an uppercase letter, and a special character.
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="w-full lg:w-3/12 pl-4 mb-10 font-light">
                                                    <label className="block text-black-700 text-sm font-bold" htmlFor="add_user">
                                                        Add / Edit / Team Status
                                                    </label>
                                                    <input
                                                        type="checkbox"
                                                        id="add_user"
                                                        name="add_user"
                                                        {...register("add_user")}
                                                        defaultChecked={assistantDetail && assistantDetail?.add_user}
                                                    />
                                                </div>
                                                <div className="w-full lg:w-3/12 pl-4 mb-10 font-light">
                                                    <label className="block text-black-700 text-sm font-bold" htmlFor="add_worker">
                                                        Add / Edit / Worker Status
                                                    </label>
                                                    <input
                                                        type="checkbox"
                                                        id="add_worker"
                                                        name="add_worker"
                                                        {...register("add_worker")}
                                                        defaultChecked={assistantDetail && assistantDetail?.add_worker}
                                                    />
                                                </div>
                                               
                                                <div className="w-full lg:w-3/12 pl-4 mb-10 font-light">
                                                    <label className="block text-black-700 text-sm font-bold" htmlFor="export_worker">
                                                        import / Export Workers List
                                                    </label>
                                                    <input
                                                        type="checkbox"
                                                        id="export_worker"
                                                        name="export_worker"
                                                        {...register("export_worker")}
                                                        defaultChecked={assistantDetail && assistantDetail?.export_worker}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex mt-5">
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light btn-w-full">
                                                    <Button className="form-button" type="submit" disabled={loading && loading == true} >{loading && loading == true ? <img style={{ width: "25px", marginRight: "15px" }} src={loader} /> : null}
                                                        Submit</Button>                                                </div>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
export default AssistantEdit
