import Sidebar from "components/SuperAdmin/Sidebar";
import Footer from "components/SuperAdmin/Footer";
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import { storeSuperAdminEmploye, resetSuperAdminEmployeData, getCompany } from "Services/action";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import loader from "assets/img/loading.gif";

const AddEmploye = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const inputFile = useRef(null);
    const [loading, setLoading] = useState(false);
    const [base64Image, setBase64Image] = useState('');

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64Image(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const errorNotify = (error) =>
        toast.error(error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();


    function onSubmit(employeData) {
        console.log("employeData?.companies",employeData?.companies)
        // const companies = Array.isArray(employeData?.companies);
        // if (companies == false) {
        //     const categoriesIds = employeData?.companies?.toString().split(",");
        //     employeData.companies = categoriesIds
        // }
        employeData.profileImage = base64Image;

         dispatch(resetSuperAdminEmployeData());
         dispatch(storeSuperAdminEmploye(employeData));
    }

    const initialStoredata = useSelector((state) => state?.EmployeStoreData);
    useEffect(() => {

        if (initialStoredata?.store_request == true) {
            setLoading(true);
        }
        if (initialStoredata?.success == true) {
            navigate('/superAdmin/employeList')
        }
        if (initialStoredata?.error) {
            setLoading(false);
            let errorData = initialStoredata?.message
            errorNotify(errorData);
        }
    }, [initialStoredata])

    useEffect(() => {
        dispatch(getCompany());
    }, []);
    const companyList = useSelector((state) => state?.CompanyRecord?.companyRec?.result?.list);

    const renderedItems = companyList?.map((item, index) => (
        <>
            <div class="flex items-center">
                <input id={"link-checkbox" + index} type="radio" value={item?.id} {...register('companies',{ required: true })} class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                <label htmlFor={"link-checkbox" + index} class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{item?.name}</label>

            </div>
        </>
    ));

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="md:ml-32">
                <div className="px-3 md:px-8 h-auto mt-16">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 xl:grid-cols-6">
                            <div className="xl:col-start-1 xl:col-end-7 px-4 mb-16">
                                <div>
                                    <h2 className="head-cust-color">Add Worker</h2>
                                </div>
                                <Card className="bgcustsky rounded-none">
                                    <CardBody>
                                        <form className="custom-form form-student-set" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="flex flex-wrap mt-10">
                                                <div className="w-full lg:w-6/12 pr-4 font-light">
                                                    <div class="w-full relative mb-10 h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="Name">
                                                            Name
                                                        </label>
                                                        <input {...register("name", { required: true })} placeholder="Name:" required className="w-full h-full focus:outline-none" />
                                                        {errors.name && <span className="error">Name is required.</span>}
                                                    </div>

                                                    {/* <div class="w-full mb-10 relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="email">
                                                            Email
                                                        </label>
                                                        <input type="email" {...register("email", { required: true })} placeholder="Email:" required className="w-full h-full focus:outline-none" />
                                                        {errors.email && <span className="error"> Email is required.</span>}
                                                    </div> */}
                                                    <div class="w-full mb-10 relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="phone_no">
                                                            Phone
                                                        </label>
                                                        <input type="number" {...register("phone_no", {
                                    required: true,
                                    minLength: 8, // Minimum character length
                                    maxLength: 10, // Maximum character length
                                    })} placeholder="Phone:" required className="w-full h-full remove-arrow focus:outline-none" 
                                    onInput={(e) => {
                                    if (e.target.value.length > 10) {
                                        e.target.value = e.target.value.slice(0, 10); // Truncate the value to 10 characters
                                    }
                                }}/>
                                                        {errors.phone_no && <span className="error"> Phone is required.</span>}
                                                    </div>
                                                    
                                                </div>
                                                <div className="w-full lg:w-6/12 pl-4 font-light">
                                                    <div class="w-full mb-10 relative h-11">
                                                        <div>
                                                            <h2 className="head-cust-color">Companies</h2>
                                                        </div>
                                                        {renderedItems}
                                                        {errors.companies && <span className="error">Company is required.</span>}

                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="role">
                                                            Role
                                                        </label>
                                                        <input type="text" {...register("role", { required: true })} placeholder="Role:" required className="w-full h-full focus:outline-none" />
                                                        {errors.role && <span className="error"> Role is required.</span>}
                                                    </div>
                                                </div>

                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">

                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="card_no">
                                                            Card No
                                                        </label>
                                                        <input type="text" {...register("card_no", { required: true })} placeholder="Card No.:" required className="w-full h-full focus:outline-none" />
                                                        {errors.card_no && <span className="error"> Card No. is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="expiry_date">
                                                            Expire Date
                                                        </label>
                                                        <input type="date" {...register("expiry_date", { required: true })} placeholder="Expiry Date:" required className="w-full h-full focus:outline-none" />
                                                        {errors.expiry_date && <span className="error">Expiry Date is required.</span>}
                                                    </div>
                                                </div>
                                                
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="profileImage">
                                                            Profile Image.
                                                        </label>
                                                        <input type="file" accept="image/*" onChange={handleImageChange} required className="w-full h-full focus:outline-none" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light btn-w-full">
                                                <Button className="form-button" type="submit" disabled={loading && loading == true} >{loading && loading == true ? <img style={{ width: "25px", marginRight: "15px" }} src={loader} /> : null}
                                                        Submit</Button>                                                </div>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
export default AddEmploye
