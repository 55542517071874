import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { updateProfile, getSuperAdminDetails, resetUpdateProfileData } from "Services/action";
import { ToastContainer, toast } from "react-toastify";
import loader from "assets/img/loading.gif";

export default function SettingsForm() {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [logoImg, setlogoImg] = useState();
    const [base64Image, setBase64Image] = useState('');

    const errorNotify = (error) =>
        toast.error(error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const successNotify = (succesData) =>
        toast.success(succesData, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const superAdminDetail = useSelector((state) => state?.SuperAdminDetail?.superAdminDetailRec);


    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    function onSubmit(superAdminData) {
        superAdminData.id = superAdminDetail?.id;
        superAdminData.companyLogo = base64Image;

        dispatch(updateProfile(superAdminData));
    }
    const UpdateProfileData = useSelector((state) => state?.UpdateProfile);

    useEffect(() => {
        if (UpdateProfileData?.store_request == true) {
            setLoading(true);
        }
        if (UpdateProfileData?.success == true) {
            setLoading(false);
            let succesData = "Profile Updated Successfully"
            successNotify(succesData);

            const data = {
                id: superAdminDetail?.id
            }
            dispatch(getSuperAdminDetails(data));
            dispatch(resetUpdateProfileData())
        }
        if (UpdateProfileData?.error) {
            setLoading(false);
            let errorData = UpdateProfileData?.message
            errorNotify(errorData);
        }
    }, [UpdateProfileData])
    function handleChange(e) {
        setlogoImg(URL.createObjectURL(e.target.files[0]));
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64Image(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }
    return (
        <Card>
            <ToastContainer />
            <CardHeader color="purple" contentPosition="none">
                <div className="w-full flex items-center justify-between">
                    <h2 className="text-white text-2xl">Profile</h2>
                </div>
            </CardHeader>
            <CardBody>
                <form className='custom-form form-student-set' onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-wrap mt-10">
                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                            <div class="w-full relative h-11">

                                <input {...register("name", { required: true })}
                                    defaultValue={superAdminDetail && superAdminDetail?.name}
                                    placeholder="Names:" required className="w-full h-full focus:outline-none" />
                                {errors.name && <span className="error"> Name is required.</span>}
                            </div>
                        </div>

                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                            <div class="w-full relative h-11">
                                <input type="email" defaultValue={superAdminDetail && superAdminDetail?.email}
                                    placeholder="Email:" readOnly disabled className="w-full h-full focus:outline-none" />
                            </div>
                        </div>
                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                            <div class="w-full relative h-11">
                                <input type="number"
                                    {...register("phone_no", {
                                        required: true,
                                        minLength: 10, // Minimum character length
                                        maxLength: 10, // Maximum character length
                                    })}
                                    defaultValue={superAdminDetail && superAdminDetail?.phone_no}
                                    placeholder="Phone:" required className="w-full h-full remove-arrow focus:outline-none"
                                    onInput={(e) => {
                                        if (e.target.value.length > 10) {
                                            e.target.value = e.target.value.slice(0, 10); // Truncate the value to 10 characters
                                        }
                                    }}
                                />
                                {errors.phone_no?.type === 'required' && <span className="error">Phone is required.</span>}
                                {errors.phone_no?.type === 'minLength' && <span className="error">Phone must be at least 10 characters.</span>}
                                {errors.phone_no?.type === 'maxLength' && <span className="error">Phone cannot exceed 10 characters.</span>}
                            </div>

                        </div>
                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                            <div class="w-full relative h-11">
                                <input type="text" {...register("city", { required: true })}
                                    defaultValue={superAdminDetail && superAdminDetail?.city}
                                    placeholder="City:" required className="w-full h-full focus:outline-none" />
                                {errors.city && <span className="error"> City is required.</span>}
                            </div>
                        </div>
                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                            <div class="w-full relative h-11">
                                <input type="text" {...register("state", { required: true })}
                                    defaultValue={superAdminDetail && superAdminDetail?.state}
                                    placeholder="State:" required className="w-full h-full focus:outline-none" />
                                {errors.state && <span className="error">  State is required.</span>}
                            </div>
                        </div>
                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                            <div class="w-full relative h-11">
                                <input type="text" {...register("country", { required: true })}
                                    defaultValue={superAdminDetail && superAdminDetail?.country}
                                    placeholder="Country:" required className="w-full h-full focus:outline-none" />
                                {errors.country && <span className="error"> Country is required.</span>}
                            </div>
                        </div>


                        <div className="w-full lg:w-12/12 pr-4 mb-10 font-light">
                            <div class="w-full relative">
                                {superAdminDetail?.companyLogo ? <img src={superAdminDetail?.companyLogo} style={{ width: "100px", height: "100px", float: "left", marginRight: "20px" }} /> : null}
                                {logoImg ? <img src={logoImg} style={{ width: "100px", height: "100px", float: "left", marginRight: "20px" }} /> : null}

                            </div>

                        </div>
                        <div className="w-full lg:w-12/12 pr-4 mb-10 font-light">
                            <div class="w-full relative">
                                <label class="block text-black-700 text-sm font-bold ml-4" for="companyLogo">
                                    Logo
                                </label>
                                <input type="file" className="mt-2 w-full h-full focus:outline-none" accept="image/*" onChange={handleChange} />
                            </div>

                        </div>


                    </div>
                    <div className="flex">
                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light btn-w-full">
                            <Button className="form-button" type="submit" disabled={loading && loading == true} >{loading && loading == true ? <img style={{ width: "25px", marginRight: "15px" }} src={loader} /> : null}
                                                        Submit</Button>
                        </div>
                    </div>
                </form>
            </CardBody>
        </Card>
    );
}
