import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {getStatistics, mailForward, resetMailForwardData} from 'Services/action'
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Card from '@material-tailwind/react/Card';
import CardRow from '@material-tailwind/react/CardRow';
import CardStatus from '@material-tailwind/react/CardStatus';
import CompanyIcon from 'assets/img/Company.png';
import StudentIcon from 'assets/img/student.png';
import AppliedStudentsIcon from 'assets/img/AppliedStudents.png';
import { useForm } from "react-hook-form";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const MainStatusCard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const superAdminDetail = useSelector((state) => state?.SuperAdminDetail?.superAdminDetailRec);

    const successNotify = (succesData) =>
    toast.success(succesData, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    useEffect(()=>{
        const data = {
            id: superAdminDetail?.id
        }
        dispatch(getStatistics(data))
    },[])

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const StaticsRecord = useSelector((state) => state?.StaticsRecord?.staticsRec);
  
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    
    function onSubmit(superAdminData) {
        superAdminData.id = superAdminDetail?.id;
        dispatch(mailForward());
    }

    const MailForwardData = useSelector((state) => state?.MailForwardData);
  useEffect(() => {
    if (MailForwardData?.success) {
      let succesData = MailForwardData?.message
      successNotify(succesData);

      const data = {
        id: superAdminDetail?.id
    }
    dispatch(getStatistics(data))
    dispatch(resetMailForwardData())

    

    }
  }, [MailForwardData]);

    return (
        <>
              <ToastContainer />
            <Carousel responsive={responsive}>
                <div className="px-6 mb-10 main-tiles-section">
                    <NavLink to="/superAdmin/companies">
                        <Card className="main-tiles p-0">
                            <CardRow className="inner-tiles">
                                <CardStatus className="tiles-title" title={StaticsRecord && StaticsRecord?.totalCompany} />
                                <img src={CompanyIcon} className="w-24 h-24" />
                                <CardStatus className="tiles-title-bottom" title={"Company List"} />
                            </CardRow>
                        </Card>
                    </NavLink>
                </div>
                <div className="px-6 mb-10 main-tiles-section">
                    <NavLink to="/superAdmin/employeList">
                        <Card className="main-tiles p-0">
                            <CardRow className="inner-tiles">
                                <CardStatus className="tiles-title" title={StaticsRecord && StaticsRecord?.totalAssignedEmploye} />
                                <img src={StudentIcon} className="w-24 h-24" />
                                <CardStatus className="tiles-title-bottom" title={"Assigned Workers"} />
                            </CardRow>
                        </Card>
                    </NavLink>
                </div>
                <div className="px-6 mb-10 main-tiles-section"> 
                    <NavLink to="/superAdmin/selfRegisterdEmployeList">
                        <Card className="main-tiles p-0">
                            <CardRow className="inner-tiles">
                                <CardStatus className="tiles-title" title={StaticsRecord && StaticsRecord?.totalPendingEmploye} />
                                <img src={AppliedStudentsIcon} className="w-24 h-24" />
                                <CardStatus className="tiles-title-bottom" title={"Unassigned Workers"} />
                            </CardRow>
                        </Card>
                    </NavLink>
                </div>
                
                </Carousel>
        </>
    )
}
export default MainStatusCard