import { takeLatest, call, put } from 'redux-saga/effects';

import { Get_User_Detail, Set_User_Detail, SuperAdminUserLogin, Set_SuperAdmin_Login, SuperAdmin_Login_Fail, Get_SuperAdmin_Local_Store_Data, set_SuperAdmin_Local_Store_Data, Handle_Company_Status, Set_Handle_Company_Status_Data, Set_Handle_Company_Status_Data_Fail, Reset_Company_Status_Data, Set_Reset_Company_Status_Data, Handle_User_Status, Set_Handle_User_Status_Data, Set_Handle_User_Status_Data_Fail, Reset_User_Status_Data, Set_Reset_User_Status_Data, SuperAdminUserLogout, Set_SuperAdmin_Logout, AdminUserLogin, Set_Admin_Login, Admin_Login_Fail, Get_Company_Admin_Details, Set_Company_Admin_Details, Set_Update_Profile_Request, Set_Update_Profile_Data, Update_Profile, Set_Update_Profile_Fail, Reset_Update_Profile_Data, Set_Reset_Update_Profile_Data, AdminUserLogout, Set_Admin_Logout, Get_Local_Store_Data, set_Local_Store_Data, Store_Multi_User, Store_Multi_Employe_Request, Set_Store_Multi_Employe_Data_Fail, Set_Store_Multi_Employe_Data, Set_Reset_Login_Data, Reset_Login_Data, Reset_SuperAdmin_Login_Data, Set_Reset_SuperAdmin_Login_Data, Get_Statistics, Set_Statistics, Reset_Mail_Forward_Data, Set_Reset_Mail_Forward_Data, Get_All_Report_For_Ceo, Set_All_Report_For_Ceo, Get_Super_Admin_Details, Set_Super_Admin_Details } from "./constant";

import { Mail_Forward, Set_Mail_Forward_Data, Set_Mail_Forward_Data_Fail } from "./constant"

import { Set_Reset_Company_Data, Reset_Company_Data, Store_Company, Set_Store_Company_Data,Store_Company_Request, Set_Store_Company_Data_Fail, Get_Company, Set_Company_List, Update_Company, Set_Update_Company_Data,Update_Company_Request, Set_Update_Company_Data_Fail } from "./constant"

import { Get_All_Employes, Set_All_Employes, Store_SuperAdmin_Employe, Reset_SuperAdmin_Employe_Data, Set_Reset_SuperAdmin_Employe_Data, Set_Store_SuperAdmin_Employe_Data, Store_SuperAdmin_Employe_Request, Set_Store_SuperAdmin_Employe_Data_Fail, Update_SuperAdmin_Employe, Set_Update_SuperAdmin_Employe_Data,Update_SuperAdmin_Employe_Request, Set_Update_SuperAdmin_Employe_Data_Fail } from "./constant"

//company admin store employe start
import { Get_Employe_By_Company, Set_Employe_By_Company, Store_CompanyAdmin_Employe, Store_CompanyAdmin_Employe_Request, Reset_CompanyAdmin_Employe_Data, Set_Reset_CompanyAdmin_Employe_Data, Set_Store_CompanyAdmin_Employe_Data, Set_Store_CompanyAdmin_Employe_Data_Fail, Update_CompanyAdmin_Employe, Set_Update_CompanyAdmin_Employe_Data,Update_CompanyAdmin_Employe_Request, Set_Update_CompanyAdmin_Employe_Data_Fail } from "./constant"
//company admin store employe End

import { Get_Expired_Emp_By_Company, Set_Expired_Emp_By_Company, Reset_Company_Expired_Emp_Data, Set_Reset_Company_Expired_Emp_Data } from "./constant"



import { Delete_Company_Assistant, Get_Assistant_By_Company, Set_Assistant_By_Company, Store_Company_Assistant, Store_Company_Assistant_Request, Set_Store_Company_Assistant_Data, Set_Store_Company_Assistant_Data_Fail, Reset_Company_Assistant_Data, Set_Reset_Company_Assistant_Data,Update_Company_Assistant_Request, Update_Company_Assistant, Set_Update_Company_Assistant_Data, Set_Update_Company_Assistant_Data_Fail } from "./constant"

import {Company_Forgot_Pass, Company_Forgot_Pass_Request,Set_Company_Forgot_Pass_Data,Set_Company_Forgot_Pass_Data_Fail, Reset_Company_Forgot_Pass_Data, Set_Reset_Company_Forgot_Pass_Data} from "./constant"

import {Update_Password, Update_Password_Request,Set_Update_Password_Data, Set_Update_Password_Data_Fail, Reset_Update_Password_Data, Set_Reset_Update_Password_Data } from "./constant"



import { API_URL } from "./constant"
import Axios from "axios"

function* superAdminUserLogin(data) {
    const requestData = data.data
    try {
        let uri = API_URL.concat('admin/login')
        const superAdminLoginRes = yield call(Axios.post, uri, requestData)
        if (superAdminLoginRes?.data?.status == "error") {
            let errMessage = superAdminLoginRes?.data?.message
            yield put({ type: SuperAdmin_Login_Fail, errMessage })
        } else {
            const result = superAdminLoginRes?.data?.message;
            localStorage.setItem('superAdminUserId', superAdminLoginRes?.data?.data?.accessToken);
            localStorage.setItem('superAdminUserName', superAdminLoginRes?.data?.user?.username);
            localStorage.setItem('superAdminUserEmail', superAdminLoginRes?.data?.user?.email);
            localStorage.setItem('superAdminUserPhone', superAdminLoginRes?.data?.user?.userPhone);
            localStorage.setItem('superAdmintoken', superAdminLoginRes?.data?.data?.accessToken);
            localStorage.setItem('superAdminUserRole', superAdminLoginRes?.data?.user?.role);
            localStorage.setItem('superAdminUserAddress', superAdminLoginRes?.data?.user?.userAddress);
            localStorage.setItem('superAdminUserCity', superAdminLoginRes?.data?.user?.userCity);
            localStorage.setItem('superAdminUserCountry', superAdminLoginRes?.data?.user?.userCountry);

            yield put({ type: Set_SuperAdmin_Login, result })

        }

    } catch (error) {
        console.log("Error is ", error?.response?.data?.errors)
        let errMessage = error?.response?.data?.errors
        yield put({ type: SuperAdmin_Login_Fail, errMessage })
    }
}

function* getSuperAdminLocalStoreData() {
    yield put({ type: set_SuperAdmin_Local_Store_Data })
}

function* getSuperAdminDetails(data) {
    const requestData = data.data
    try {
        let uri = API_URL.concat('admin/getSuperAdminDetails')
        const userRecord = yield call(Axios.post, uri, requestData)
        const result = userRecord?.data?.result[0];
        yield put({ type: Set_Super_Admin_Details, result })
    } catch (error) {
        console.log("Error is ", error)
        yield put({ type: Set_Super_Admin_Details })
    }
}


function* mailForward(data) {
    const requestData = data.data
    try {
        let uri = API_URL.concat('employe/mailForward')

        const storedRes = yield call(Axios.get, uri, requestData)
        const result = storedRes?.data;

        if (result?.status == 'error') {
            let errMessage = result?.data?.message
            yield put({ type: Set_Mail_Forward_Data_Fail, errMessage })
        } else {
            yield put({ type: Set_Mail_Forward_Data, result })
        }
    } catch (error) {
        console.log("Error is ", error)
        yield put({ type: Set_Mail_Forward_Data_Fail, error })
    }
}

///////SuperAdmin Company Start


function* storeCompany(data) {
    const requestData = data.data
    console.log("requestData",requestData)
    yield put({ type: Store_Company_Request })
    try {
        let uri = API_URL.concat('admin/company/save')
        const storedRes = yield call(Axios.post, uri, requestData)
        const result = storedRes?.data;
        if (result?.status == 'error') {
            let errMessage = result?.data?.message
            yield put({ type: Set_Store_Company_Data_Fail, errMessage })
        } else {
            yield put({ type: Set_Store_Company_Data, result })
        }
    } catch (error) {
        console.log("Error is ", error)
        yield put({ type: Set_Store_Company_Data_Fail, error })
    }
}


function* updateCompany(data) {
    const requestData = data.data
    
    yield put({ type: Update_Company_Request })

    try {
        let uri = API_URL.concat('admin/companyUpdate')
        const storedRes = yield call(Axios.post, uri, requestData)
        const result = storedRes?.data;
        if (result?.status == 'error') {
            let errMessage = result?.data?.message
            yield put({ type: Set_Update_Company_Data_Fail, errMessage })
        } else {
            yield put({ type: Set_Update_Company_Data, result })
        }
    } catch (error) {
        console.log("Error is ", error)
        yield put({ type: Set_Update_Company_Data_Fail, error })
    }
}
function* resetCompanyData() {
    yield put({ type: Set_Reset_Company_Data })
}

function* getCompany(data) {
    const requestData = data?.data
    try {
        let uri = API_URL.concat('admin/companies')
        const companyListRes = yield call(Axios.post, uri, requestData)
        const result = companyListRes?.data;
        yield put({ type: Set_Company_List, result })
    } catch (error) {
        yield put({ type: Set_Company_List, error })
        console.log("Error is ", error)
    }
}




///////End SuperAdmin Company




///////SuperAdmin Employee Start


function* storeSuperAdminEmploye(data) {
    const requestData = data.data
    yield put({ type: Store_SuperAdmin_Employe_Request })
    try {
        let uri = API_URL.concat('admin/addEmploye')
        const storedRes = yield call(Axios.post, uri, requestData)
        const result = storedRes.data;
        if (result?.status == 'error') {
            let errMessage = result?.data?.message
            yield put({ type: Set_Store_SuperAdmin_Employe_Data_Fail, errMessage })
        } else {
            yield put({ type: Set_Store_SuperAdmin_Employe_Data, result })
        }
    } catch (error) {
        console.log("Error is ", error)
        yield put({ type: Set_Store_SuperAdmin_Employe_Data_Fail, error })
    }
}


function* updateSuperAdminEmploye(data) {
    const requestData = data.data
    
    yield put({ type: Update_SuperAdmin_Employe_Request })

    try {
        let uri = API_URL.concat('admin/updateBySuperAdmin')
        const storedRes = yield call(Axios.post, uri, requestData)
        const result = storedRes.data;
        if (result?.status == 'error') {
            let errMessage = result?.data?.message
            yield put({ type: Set_Update_SuperAdmin_Employe_Data_Fail, errMessage })
        } else {
            yield put({ type: Set_Update_SuperAdmin_Employe_Data, result })
        }
    } catch (error) {
        console.log("Error is ", error)
        yield put({ type: Set_Update_SuperAdmin_Employe_Data_Fail, error })
    }
}

function* resetSuperAdminEmployeData() {
    yield put({ type: Set_Reset_SuperAdmin_Employe_Data })
}

function* getAllEmployes(data) {
    const requestData = data?.data
    try {
        let uri = API_URL.concat('admin/employes')
        const employeListRes = yield call(Axios.post, uri, requestData)

        const result = employeListRes?.data;
        yield put({ type: Set_All_Employes, result })
    } catch (error) {
        yield put({ type: Set_All_Employes, error })
        console.log("Error is ", error)
    }
}





///////End SuperAdmin Employe


function* getUserDetail(data) {
    const requestData = data.data
    try {
        let uri = API_URL.concat('/view-university-details?universityID=')
        uri = uri.concat(requestData.universityID)

        const userRecord = yield call(Axios.get, uri, requestData)
        const result = userRecord?.data?.message;
        yield put({ type: Set_User_Detail, result })
    } catch (error) {
        console.log("Error is ", error)
        yield put({ type: Set_User_Detail })
    }
}


function* handleUserStatus(data) {
    const requestData = data.data
    try {
        let uri = API_URL.concat('admin/employeeHandleStatus')
        const userStatusRes = yield call(Axios.post, uri, requestData)
        const result = userStatusRes.data;
        console.log("result", result)
        yield put({ type: Set_Handle_User_Status_Data, result })
    } catch (error) {
        console.log("Error is ", error)
        yield put({ type: Set_Handle_User_Status_Data_Fail })
    }
}

function* resetUserStatusData() {
    yield put({ type: Set_Reset_User_Status_Data })
}


function* handleCompanyStatus(data) {
    const requestData = data.data
    try {
        let uri = API_URL.concat('admin/companyHandleStatus')
        const userStatusRes = yield call(Axios.post, uri, requestData)
        const result = userStatusRes.data;
        if (result?.status == 'error') {
            let errMessage = result?.message
            yield put({ type: Set_Handle_Company_Status_Data_Fail, errMessage })
        } else {
            yield put({ type: Set_Handle_Company_Status_Data, result })
        }
    } catch (error) {
        //console.log("Error is ", error)
        yield put({ type: Set_Handle_Company_Status_Data_Fail })
    }
}

function* resetCompanyStatusData() {
    yield put({ type: Set_Reset_Company_Status_Data })
}

function* superAdminUserLogout(data) {
    yield put({ type: Set_SuperAdmin_Logout })
}
function* adminUserLogin(data) {
    const requestData = data.data
    try {
        let uri = API_URL.concat('company/companyLogin')
        const adminLoginRes = yield call(Axios.post, uri, requestData)
        if (adminLoginRes?.data?.status == "error") {
            let errMessage = adminLoginRes?.data?.message
            yield put({ type: Admin_Login_Fail, errMessage })
        } else {
            const result = adminLoginRes?.data?.user;
            localStorage.setItem('adminUserId', adminLoginRes.data?.user?._id);
            localStorage.setItem('superAdminId', adminLoginRes.data.adminId);
            localStorage.setItem('adminName', adminLoginRes.data?.user?.name);
            localStorage.setItem('adminUserName', adminLoginRes.data?.user?.name);
            localStorage.setItem('adminUserEmail', adminLoginRes.data?.user?.email);
            localStorage.setItem('adminUserPhone', adminLoginRes.data?.user?.phoneNumber);
            localStorage.setItem('admintoken', adminLoginRes.data?.data?.accessToken);
            localStorage.setItem('adminUserRole', adminLoginRes.data?.user?.role);
            localStorage.setItem('adminUserAddress', adminLoginRes.data?.user?.location);
            localStorage.setItem('adminUserCity', adminLoginRes.data?.user?.city);
            localStorage.setItem('adminUserCountry', adminLoginRes.data?.user?.country);
            yield put({ type: Set_Admin_Login, result })
        }
    } catch (error) {
        console.log("Error is ", error)
        let errMessage = error?.response?.data?.errors
        yield put({ type: Admin_Login_Fail, errMessage })
    }
}
function* resetLoginData() {
    yield put({ type: Set_Reset_Login_Data })
}

function* companyForgotPass(data) {
    const requestData = data.data
    yield put({ type: Company_Forgot_Pass_Request })

    try {
        let uri = API_URL.concat('admin/companyForgotPass')
        const storedRes = yield call(Axios.post, uri, requestData)
        const result = storedRes.data;
        console.log("result",result);
        if (result?.status == 'error') {
            let errMessage = result?.data?.message
            yield put({ type: Set_Company_Forgot_Pass_Data_Fail, errMessage })
        } else {
            yield put({ type: Set_Company_Forgot_Pass_Data, result })
        }
    } catch (error) {
        //console.log("Error is ", error)
       // yield put({ type: Set_Company_Forgot_Pass_Data_Fail, error })
    }
}




function* updatePassword(data) {
    const requestData = data.data
    yield put({ type: Update_Password_Request })

    try {
        let uri = API_URL.concat('admin/companyUpdatePassword')
        const storedRes = yield call(Axios.post, uri, requestData)
        const result = storedRes.data;
        if (result?.status == 'error') {
            let errMessage = result?.message
            yield put({ type: Set_Update_Password_Data_Fail, errMessage })
        } else {
            yield put({ type: Set_Update_Password_Data, result })
        }
    } catch (error) {
        //console.log("Error is ", error)
        yield put({ type: Set_Update_Password_Data_Fail, error })
    }
}



function* resetUpdatePasswordData() {
    yield put({ type: Set_Reset_Update_Password_Data })
}

function* resetCompanyForgotPassData() {
    yield put({ type: Set_Reset_Company_Forgot_Pass_Data })
}


function* getCompanyAdminDetails(data) {
    const requestData = data.data
    try {
        let uri = API_URL.concat('company/getCompanyAdminDetails')
        const userRecord = yield call(Axios.post, uri, requestData)
        const result = userRecord?.data?.result[0];
        yield put({ type: Set_Company_Admin_Details, result })
    } catch (error) {
        console.log("Error is ", error)
        yield put({ type: Set_Company_Admin_Details })
    }
}


function* storeCompanyAdminEmploye(data) {
    const requestData = data.data
    yield put({ type: Store_CompanyAdmin_Employe_Request })

    try {
        let uri = API_URL.concat('admin/addEmploye')
        const storedRes = yield call(Axios.post, uri, requestData)

        const result = storedRes.data;
        if (result?.status == 'error') {
            let errMessage = result?.data?.message
            yield put({ type: Set_Store_CompanyAdmin_Employe_Data_Fail, errMessage })
        } else {
            yield put({ type: Set_Store_CompanyAdmin_Employe_Data, result })
        }
    } catch (error) {
        console.log("Error is ", error)
        yield put({ type: Set_Store_CompanyAdmin_Employe_Data_Fail, error })
    }
}
function* resetCompanyAdminEmployeData() {
    yield put({ type: Set_Reset_CompanyAdmin_Employe_Data })
}
function* getEmployeByCompany(data) {
    const requestData = data.data
    try {
        let uri = API_URL.concat('admin/employeListByCompany')
        const employeListRes = yield call(Axios.post, uri, requestData)
        const result = employeListRes?.data;
        yield put({ type: Set_Employe_By_Company, result })
    } catch (error) {
        yield put({ type: Set_Employe_By_Company, error })
        console.log("Error is ", error)
    }
}




function* updateCompanyAdminEmploye(data) {
    const requestData = data.data
    yield put({ type: Update_CompanyAdmin_Employe_Request })

    try {
        let uri = API_URL.concat('admin/employeUpdate')
        const storedRes = yield call(Axios.post, uri, requestData)

        const result = storedRes.data;
        if (result?.status == 'error') {
            let errMessage = result?.data?.message
            yield put({ type: Set_Update_CompanyAdmin_Employe_Data_Fail, errMessage })
        } else {
            yield put({ type: Set_Update_CompanyAdmin_Employe_Data, result })
        }
    } catch (error) {
        console.log("Error is ", error)
        yield put({ type: Set_Update_CompanyAdmin_Employe_Data_Fail, error })
    }
}


function* resetCompanyExpiredEmpData() {
    yield put({ type: Set_Reset_Company_Expired_Emp_Data })
}
function* GetExpiredEmpByCompany(data) {
    const requestData = data.data
    try {
        let uri = API_URL.concat('admin/expiredEmpListByCompany')
        const employeListRes = yield call(Axios.post, uri, requestData)
        const result = employeListRes?.data;
        yield put({ type: Set_Expired_Emp_By_Company, result })
    } catch (error) {
        yield put({ type: Set_Expired_Emp_By_Company, error })
        console.log("Error is ", error)
    }
}


function* getAssistantByCompany(data) {
    const requestData = data.data
    try {
        let uri = API_URL.concat('admin/assistantListByCompany')
        const employeListRes = yield call(Axios.post, uri, requestData)
        const result = employeListRes?.data;
        yield put({ type: Set_Assistant_By_Company, result })
    } catch (error) {
        yield put({ type: Set_Assistant_By_Company, error })
        console.log("Error is ", error)
    }
}

function* storeCompanyAssistant(data) {
    const requestData = data.data
    yield put({ type: Store_Company_Assistant_Request })

    try {
        let uri = API_URL.concat('admin/addAssistant')
        const storedRes = yield call(Axios.post, uri, requestData)

        const result = storedRes.data;
        if (result?.status == 'error') {
            let errMessage = result?.data?.message
            yield put({ type: Set_Store_Company_Assistant_Data_Fail, errMessage })
        } else {
            yield put({ type: Set_Store_Company_Assistant_Data, result })
        }
    } catch (error) {
        console.log("Error is ", error)
        yield put({ type: Set_Store_Company_Assistant_Data_Fail, error })
    }
}


function* updateCompanyAssistant(data) {
    const requestData = data.data
    yield put({ type: Update_Company_Assistant_Request })

    try {
        let uri = API_URL.concat('admin/updateAssistant')
        const storedRes = yield call(Axios.post, uri, requestData)

        const result = storedRes.data;
        if (result?.status == 'error') {
            let errMessage = result?.data?.message
            yield put({ type: Set_Update_Company_Assistant_Data_Fail, errMessage })
        } else {
            yield put({ type: Set_Update_Company_Assistant_Data, result })
        }
    } catch (error) {
        console.log("Error is ", error)
        yield put({ type: Set_Update_Company_Assistant_Data_Fail, error })
    }
}
function* resetCompanyAssistantData() {
    yield put({ type: Set_Reset_Company_Assistant_Data })
}

function* updateProfile(data) {
    const requestData = data.data
    yield put({ type: Set_Update_Profile_Request })
    try {
        let uri = API_URL.concat('admin/updateProfile')
        const dataStoreResult = yield call(Axios.post, uri, requestData)
        const result = dataStoreResult?.data;
        console.log("result", result)
        yield put({ type: Set_Update_Profile_Data, result })
    } catch (error) {
        console.log("Error is ", error)
        yield put({ type: Set_Update_Profile_Fail, error })
    }
}

function* resetUpdateProfileData() {
    yield put({ type: Set_Reset_Update_Profile_Data })
}

function* resetSuperAdminLoginData() {
    yield put({ type: Set_Reset_SuperAdmin_Login_Data })
}


function* getLocalStoreData() {
    yield put({ type: set_Local_Store_Data })
}
function* adminUserLogout(data) {
    yield put({ type: Set_Admin_Logout })
}

function* storeMultiUser(data) {
    const requestData = data.data
    yield put({ type: Store_Multi_Employe_Request })
    try {
        let uri = API_URL.concat('admin/addMultiEmploye')
        const storedRes = yield call(Axios.post, uri, requestData)
        console.log("storedRes", storedRes)
        const result = storedRes?.data;
        if (result?.status == 'error') {
            let errMessage = result?.data?.message
            yield put({ type: Set_Store_Multi_Employe_Data_Fail, errMessage })
        } else {
            yield put({ type: Set_Store_Multi_Employe_Data, result })
        }
    } catch (error) {
        console.log("Error is ", error)
        yield put({ type: Set_Store_Multi_Employe_Data_Fail, error })
    }

}
function* getStatistics(data) {
    const requestData = data.data
    try {
        let uri = API_URL.concat('admin/getStatistics')
        const statisticsRes = yield call(Axios.post, uri, requestData)
        const result = statisticsRes.data;
        yield put({ type: Set_Statistics, result })
    } catch (error) {
        yield put({ type: Set_Statistics, error })
        //console.log("Error is ", error)
    }
}
function* resetMailForwardData() {
    yield put({ type: Set_Reset_Mail_Forward_Data })
}
function* getAllReportForCeo(data) {
    const requestData = data.data
    try {
        let uri = API_URL.concat('/getAllReportForCeo')
        const scannIssueReportsRes = yield call(Axios.get, uri)
        const result = scannIssueReportsRes.data;
        yield put({ type: Set_All_Report_For_Ceo, result })
    } catch (error) {
        yield put({ type: Set_All_Report_For_Ceo, error })
        console.log("Error is ", error)
    }
}

function* deleteCompanyAssistant(id) {
    yield put({ type: Delete_Company_Assistant })

    try {
        let uri = API_URL.concat('admin/deleteAssistant/'+id)
        const storedRes = yield call(Axios.delete, uri)

        const result = storedRes.data;
        if (result?.status === 'error') {
            let errMessage = result?.data?.message
            console.log("Error is : ", errMessage)
            //yield put({ type: Set_Update_Company_Assistant_Data_Fail, errMessage })
        } else {
            yield put({ type: Set_Update_Company_Assistant_Data, result })
        }
    } catch (error) {
        console.log("Error is ", error)
        //yield put({ type: Set_Update_Company_Assistant_Data_Fail, error })
    }
}

function* mainSaga() {
    yield takeLatest(Get_User_Detail, getUserDetail)
    yield takeLatest(SuperAdminUserLogin, superAdminUserLogin)
    yield takeLatest(Get_SuperAdmin_Local_Store_Data, getSuperAdminLocalStoreData)
    yield takeLatest(Handle_User_Status, handleUserStatus)
    yield takeLatest(Reset_User_Status_Data, resetUserStatusData)

    yield takeLatest(Handle_Company_Status, handleCompanyStatus)
    yield takeLatest(Reset_Company_Status_Data, resetCompanyStatusData)



    yield takeLatest(Get_Super_Admin_Details, getSuperAdminDetails)
    yield takeLatest(Mail_Forward, mailForward)




    ////SuperAdmin company
    yield takeLatest(Store_Company, storeCompany)
    yield takeLatest(Update_Company, updateCompany)
    yield takeLatest(Reset_Company_Data, resetCompanyData)
    yield takeLatest(Get_Company, getCompany)

    //////End SuperAdmin company

    ////SuperAdmin Employes
    yield takeLatest(Store_SuperAdmin_Employe, storeSuperAdminEmploye)
    yield takeLatest(Update_SuperAdmin_Employe, updateSuperAdminEmploye)


    yield takeLatest(Reset_SuperAdmin_Employe_Data, resetSuperAdminEmployeData)
    yield takeLatest(Get_All_Employes, getAllEmployes)

    //////End SuperAdmin Employes


    yield takeLatest(Get_Statistics, getStatistics)

    yield takeLatest(Reset_Mail_Forward_Data, resetMailForwardData)

    yield takeLatest(SuperAdminUserLogout, superAdminUserLogout)

    yield takeLatest(AdminUserLogin, adminUserLogin)
    yield takeLatest(Reset_Login_Data, resetLoginData)
    yield takeLatest(Get_Company_Admin_Details, getCompanyAdminDetails)

    yield takeLatest(Company_Forgot_Pass, companyForgotPass)
    yield takeLatest(Update_Password, updatePassword)
    
    yield takeLatest(Reset_Update_Password_Data, resetUpdatePasswordData)

    yield takeLatest(Reset_Company_Forgot_Pass_Data, resetCompanyForgotPassData)


    yield takeLatest(Update_Profile, updateProfile)
    yield takeLatest(Reset_Update_Profile_Data, resetUpdateProfileData)
    yield takeLatest(Reset_SuperAdmin_Login_Data, resetSuperAdminLoginData)
    yield takeLatest(AdminUserLogout, adminUserLogout)
    yield takeLatest(Get_Local_Store_Data, getLocalStoreData)
    yield takeLatest(Store_Multi_User, storeMultiUser)
    yield takeLatest(Get_All_Report_For_Ceo, getAllReportForCeo)


    yield takeLatest(Store_CompanyAdmin_Employe, storeCompanyAdminEmploye)
    yield takeLatest(Reset_CompanyAdmin_Employe_Data, resetCompanyAdminEmployeData)
    yield takeLatest(Get_Employe_By_Company, getEmployeByCompany)
    yield takeLatest(Update_CompanyAdmin_Employe, updateCompanyAdminEmploye)

    yield takeLatest(Reset_Company_Expired_Emp_Data, resetCompanyExpiredEmpData)
    yield takeLatest(Get_Expired_Emp_By_Company, GetExpiredEmpByCompany)

    yield takeLatest(Get_Assistant_By_Company, getAssistantByCompany)
    yield takeLatest(Store_Company_Assistant, storeCompanyAssistant)
    yield takeLatest(Update_Company_Assistant, updateCompanyAssistant)

    yield takeLatest(Reset_Company_Assistant_Data, resetCompanyAssistantData)
    yield takeLatest(Delete_Company_Assistant, deleteCompanyAssistant)


    
}
export default mainSaga