import { NavLink, useNavigate } from 'react-router-dom';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import Dropdown from '@material-tailwind/react/Dropdown';
import DropdownItem from '@material-tailwind/react/DropdownItem';
import locationicon from "assets/img/location.png";
import { useDispatch, useSelector } from 'react-redux';
import { superAdminLogout } from "../../Services/action";
export default function SuperAdminNavbar({ showSidebar, setShowSidebar }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const superAdminDetail = useSelector((state) => state?.SuperAdminDetail?.superAdminDetailRec);
  const logout = () => {
    dispatch(superAdminLogout());
    navigate("/superAdmin");
  };
  return (
    <nav className="custom-navbar md:ml-32 py-8 px-3">
      <div className="container max-w-full mx-auto flex items-center justify-between md:pr-8 md:pl-10">
        <div className="md:hidden">
          <Button
            color="transparent"
            buttonType="link"
            size="lg"
            iconOnly
            rounded
            ripple="light"
            onClick={() => setShowSidebar("left-0")}
          >
            <Icon name="menu" size="2xl" color="white" />
          </Button>
          <div
            className={`absolute top-2 md:hidden ${showSidebar === "left-0" ? "left-64" : "-left-64"
              } z-50 transition-all duration-300`}
          >
            <Button
              color="transparent"
              buttonType="link"
              size="lg"
              iconOnly
              rounded
              ripple="light"
              onClick={() => setShowSidebar("-left-64")}
            >
              <Icon name="close" size="2xl" color="white" />
            </Button>
          </div>
        </div>

        <div className="flex justify-end items-center w-full">
          <div className="flex">
            <div className="-mr-4 ml-6 profile-button custom-profile">
              <Dropdown
                color="transparent"
                buttonText={
                  <div className="w-24 profile-section">
                    {superAdminDetail && superAdminDetail?.name}
                  </div>
                }
                rounded
                style={{
                  padding: 0,
                  color: "white",
                }}
              >
                <NavLink to="/company/settings">
                </NavLink>
                <div className='profile-data'>
                  
                  <p className="click-open-btn"><img src={locationicon} className="location-img" /> <a href="#">{superAdminDetail && superAdminDetail?.city + ", " + superAdminDetail?.state + ", " + superAdminDetail?.country}</a></p>
                  <p className="click-open-btn"><Icon name="phone" size="1xl" color="black" /> <a href="#">{superAdminDetail && superAdminDetail?.phone_no}</a></p>
                  <p className="sign-button-p"><DropdownItem color="lightBlue" onClick={() => {
                    const confirmBox = window.confirm("Are you sure you want to logout?");
                    if (confirmBox === true) {
                      logout();
                    }
                  }}>Sign out</DropdownItem></p>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
