import MainStatusCard from "components/Company/MainStatusCard";
import Sidebar from "components/Company/Sidebar";
import Footer from "components/Company/Footer";
import "react-multi-carousel/lib/styles.css";
import { useEffect } from "react";
import { resetLoginData } from "Services/action";
import { useDispatch } from "react-redux";


const Dashboard = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(resetLoginData());
  },[])

  return (
    <>
      <Sidebar />
      <div className="md:ml-32">
        <div className="px-3 md:px-8 mt-16">
          <div>
            <MainStatusCard />
          </div>
         
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Dashboard;
