import SettingsForm from 'components/Company/SettingsForm';
import ProfileCard from 'components/Company/ProfileCard';
import Sidebar from 'components/Company/Sidebar';
import Footer from 'components/Company/Footer';
const Profile = () => {
    return (
        <>
            <Sidebar />
            <div className="md:ml-32">
                <div className="px-3 md:px-8 h-auto mt-16">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 xl:grid-cols-6">
                            <div className="xl:col-start-1 xl:col-end-5 px-4 mb-16">
                                <SettingsForm />
                            </div>
                            <div className="xl:col-start-5 xl:col-end-7 px-4 mb-16 mt-14">
                                <ProfileCard />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Profile
