import React from "react";
import Home from "pages/Front/Home";
import SuperAdminLogin from "pages/SuperAdmin/Login";
import SuperAdminDashboard from 'pages/SuperAdmin/Dashboard';
import SuperAdminProfile from 'pages/SuperAdmin/Profile';

//superadmin company start
import Companies from 'pages/SuperAdmin/Company/Companies';
import AddCompany from 'pages/SuperAdmin/Company/AddCompany';
import CompanyDetails from 'pages/SuperAdmin/Company/CompanyDetails';
import EditCompany from 'pages/SuperAdmin/Company/EditCompany';

//superadmin company End

//superadmin Employe start

import SuperAdminEmployeList from 'pages/SuperAdmin/Employe/Employes';
import SuperAdminSelfRegisteredEmployeList from 'pages/SuperAdmin/Employe/SelfRegisteredEmploye';
import SuperAdminAddEmployes from 'pages/SuperAdmin/Employe/AddEmploye';
import SuperAdminEditEmployes from 'pages/SuperAdmin/Employe/EditEmploye';
import SuperAdminAddMultiEmployes from 'pages/SuperAdmin/Employe/AddMultiEmploye';
import SuperAdminEmployeDetails from 'pages/SuperAdmin/Employe/EmployeDetails';
 //superadmin Employe end




//Company Admin Start
import CompanyLogin from "pages/Company/Login";
import CompanyForgotPassword from "pages/Company/CompanyForgotPassword";


import Dashboard from 'pages/Company/Dashboard';
import Profile from 'pages/Company/Profile';
//Company Admin End


//Company Admin Employe Routes start
import EmployeAdd from "pages/Company/Employe/EmployeAdd";
import EmployeList from 'pages/Company/Employe/EmployeList';
import ExpiredEmpList from 'pages/Company/Employe/ExpiredEmpList';
import EmployeDetail from 'pages/Company/Employe/EmployeDetail';
import EmployeEdit from "pages/Company/Employe/EmployeEdit";
import MultiEmplyeAdd from "pages/Company/Employe/MultiEmplyeAdd";
//Company Admin Employe Routes end

//Company Admin AssistentList Routes start
import AssistantList from 'pages/Company/assistant/AssistantList';
import AssistantAdd from "pages/Company/assistant/AssistantAdd";
import AssistantEdit from "pages/Company/assistant/AssistantEdit";

//Company Admin AssistentList Routes end


import { Route, Routes } from "react-router-dom";
const WebRoutes = () => {
    return (
        <Routes>
            {/*
             SuperAdmin Routes Start
            */}
            <Route path="/" index element={<SuperAdminLogin />} />
            <Route path="/superAdmin" index element={<SuperAdminLogin />} />
            <Route path="/superAdmin/dashboard" index element={<SuperAdminDashboard />} />
            <Route path="/superAdmin/profile" index element={<SuperAdminProfile />} />
            <Route path="/superAdmin/companies" index element={<Companies />} />
            <Route path="/superAdmin/addCompany" index element={<AddCompany />} />
            <Route path="/superAdmin/companyDetails" index element={<CompanyDetails />} />
            <Route path="/superAdmin/editCompany" index element={<EditCompany />} />

            
            <Route path="/superAdmin/employeList" index element={<SuperAdminEmployeList />} />
            <Route path="/superAdmin/selfRegisterdEmployeList" index element={<SuperAdminSelfRegisteredEmployeList />} />

            <Route path="/superAdmin/addEmploye" index element={<SuperAdminAddEmployes  />} />
            <Route path="/superAdmin/employeDetails" index element={<SuperAdminEmployeDetails />} />
            <Route path="/superAdmin/editEmploye" index element={<SuperAdminEditEmployes  />} />



            <Route path="/superAdmin/addMultiEmploye" index element={<SuperAdminAddMultiEmployes  />} />


            {/*
             Company Admin Routes Start
            */}
            <Route path="/company" index element={<CompanyLogin />} />
            <Route path="/company/forgotPassword" index element={<CompanyForgotPassword />} />
            <Route path="/company/dashboard" index element={<Dashboard />} />
            <Route path="/company/profile" index element={<Profile />} />

            <Route path="/company/employeAdd" index element={<EmployeAdd />} />
            <Route path="/company/employeList" index element={<EmployeList />} />
            <Route path="/company/expiredEmpList" index element={<ExpiredEmpList />} />

            
            <Route path="/company/employeDetail" index element={<EmployeDetail />} />
            <Route path="/company/employeEdit" index element={<EmployeEdit />} />
            <Route path="/company/multiEmployeAdd" index element={<MultiEmplyeAdd  />} />

            <Route path="/company/assistantList" index element={<AssistantList />} />
            <Route path="/company/assistantAdd" index element={<AssistantAdd />} />
            <Route path="/company/assistantEdit" index element={<AssistantEdit />} />

            

        </Routes>
    )
}
export default WebRoutes