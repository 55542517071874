import Button from "@material-tailwind/react/Button";
import Card from "@material-tailwind/react/Card";
import CardBody from "@material-tailwind/react/CardBody";
import Footer from "components/Company/Footer";
import React, { useEffect, useRef, useState } from "react";
import Sidebar from "components/Company/Sidebar";
import loader from "assets/img/loading.gif";
import { resetCompanyAssistantData, storeCompanyAssistant } from "Services/action";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const AssistantAdd = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const companyAdminData = useSelector((state) => state?.CompanyAdminDetail?.companyAdminDetailRec);
    const errorNotify = (error) =>
        toast.error(error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();


    function onSubmit(assistantData) {
        dispatch(storeCompanyAssistant(assistantData));
        dispatch(resetCompanyAssistantData());
    }

    const validatePassword = (password) => {
        const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;    
        return strongPasswordRegex.test(password);
    };

    const initialStoredata = useSelector((state) => state?.CompanyAssistantStoreData);

    useEffect(() => {

        if (initialStoredata?.store_request == true) {
            setLoading(true);
        }
        if (initialStoredata?.success == true) {
            navigate('/company/assistantList')
        }
        if (initialStoredata?.error) {
            setLoading(false);
            let errorData = initialStoredata?.message
            errorNotify(errorData);
        }
    }, [initialStoredata])

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="md:ml-32">
                <div className="px-3 md:px-8 h-auto mt-16">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 xl:grid-cols-6">
                            <div className="xl:col-start-1 xl:col-end-7 px-4 mb-16">
                                <div>
                                    <h2 className="head-cust-color">Add Team Member</h2>
                                </div>
                                <Card className="bgcustsky rounded-none">
                                    <CardBody>
                                        <form className="custom-form form-student-set" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="flex flex-wrap mt-10">
                                                <div className="w-full lg:w-4/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="Name">
                                                            Name
                                                        </label>
                                                        <input type="hidden" {...register("created_by", { required: true })} required value={companyAdminData?.id} />
                                                        <input type="text" {...register("name", { required: true })} placeholder="Name:" required className="w-full h-full focus:outline-none" />
                                                        {errors.name && <span className="error"> Name is required.</span>}
                                                    </div>
                                                </div>
                                                
                                                <div className="w-full lg:w-4/12 pl-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="phone_no">
                                                            Phone
                                                        </label>
                                                        <input type="number" {...register("phone_no", {
                                                            required: true,
                                                            minLength: 8, // Minimum character length
                                                            maxLength: 10, // Maximum character length
                                                        })} placeholder="Phone:" required className="w-full h-full remove-arrow focus:outline-none"
                                                            onInput={(e) => {
                                                                if (e.target.value.length > 10) {
                                                                    e.target.value = e.target.value.slice(0, 10); // Truncate the value to 10 characters
                                                                }
                                                            }} />
                                                        {errors.phone_no && <span className="error"> Phone is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-4/12 pl-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="password">
                                                            Please enter a strong password  (<a href="javascript:void(0)" title="Password must be at least 8 characters, must contain a number, a smaller case letter and an uppercase letter and a special character.">?</a>)
                                                        </label>
                                                        <input type="text" {...register("password", {
                                                            required: true,
                                                            minLength: 8,
                                                            validate: validatePassword
                                                        })} placeholder="Password" required className="w-full h-full focus:outline-none" />
                                                        {errors.password && (
                                                            <span className="error">
                                                            {errors.password.type === "required"
                                                                ? "Password is required."
                                                                : "Password must be at least 8 characters, must contain a number, a smaller case letter and an uppercase letter and a special character."}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="w-full lg:w-3/12 pl-4 mb-10 font-light">
                                                    <label className="block text-black-700 text-sm font-bold" htmlFor="add_user">
                                                        Add / Edit / Team Status
                                                    </label>
                                                    <input
                                                        type="checkbox"
                                                        id="add_user"
                                                        name="add_user"
                                                        value={1}
                                                        {...register("add_user")}
                                                    />
                                                </div>
                                                <div className="w-full lg:w-3/12 pl-4 mb-10 font-light">
                                                    <label className="block text-black-700 text-sm font-bold" htmlFor="add_worker">
                                                        Add / Edit / Worker Status
                                                    </label>
                                                    <input
                                                        type="checkbox"
                                                        id="add_worker"
                                                        name="add_worker"
                                                        value={1}
                                                        {...register("add_worker")}
                                                    />
                                                </div>

                                                <div className="w-full lg:w-3/12 pl-4 mb-10 font-light">
                                                    <label className="block text-black-700 text-sm font-bold" htmlFor="export_worker">
                                                        import / Export Workers List
                                                    </label>
                                                    <input
                                                        type="checkbox"
                                                        id="export_worker"
                                                        name="export_worker"
                                                        value={1}
                                                        {...register("export_worker")}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="w-full lg:w-6/12 pl-4 mb-10 font-light btn-w-full">
                                                    <Button className="form-button" type="submit" disabled={loading && loading == true} >{loading && loading == true ? <img style={{ width: "25px", marginRight: "15px" }} src={loader} /> : null}
                                                        Submit</Button>
                                                </div>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
export default AssistantAdd
